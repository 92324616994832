import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  styled,
  Table,
  TableRow,
  useTheme,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import AppPagination from "components/AppPagination";
import Scrollbar from "components/ScrollBar";
import BlankCheckBoxIcon from "icons/BlankCheckBoxIcon";
import CheckBoxIcon from "icons/CheckBoxIcon";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import columnShape from "page-sections/user-list/columnShape";
import HeadingArea from "page-sections/user-list/heading-area";
import UserService from "../services/UserService";
import { connect } from "react-redux";
import DeleteDialog from "components/shared/DeleteDialog";
import CircularProgress from "@mui/material/CircularProgress";
import {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { getUsers } from "redux/actions/user";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { arrayIsEmpty } from "utils/utils";
import NoResultFound from "components/shared/NoResultFound";
import { H5, H6 } from "components/Typography";
import { LocalFireDepartment } from "@mui/icons-material";
import SearchInput from "components/input-fields/SearchInput";
import AddEmployeeModal from "page-sections/data-table/dataTableV2/AddEmployeeModal";
import { UserContextData } from "App";
import PaginanationAndLimit from "components/shared/PaginanationAndLimit";

// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 13,
  // fontWeight: 500,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const SelectCheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    if (resolvedRef) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);
  return (
    <Checkbox
      {...rest}
      disableRipple
      ref={resolvedRef}
      icon={<BlankCheckBoxIcon fontSize="small" color="disabled" />}
      checkedIcon={<CheckBoxIcon fontSize="small" color="primary" />}
    />
  );
});

const UserListView = (props) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const { profileReloaded } = useContext(UserContextData);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [num_pages, setNumPages] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [userdata, setUserdata] = useState(null);
  const [sortedUser, setSortedUser] = useState(null);
  const [vendor_count, setVendor_count] = useState(null);
  const [option, setOption] = useState("active");

  // useEffect(() => {
  //   console.log("props.users", props.users);
  //   let sortedUser =
  //     props.users &&
  //     props.users.sort(function (a, b) {
  //       let today = new Date();
  //       let _a = a.last_active
  //         ? new Date(a.last_active)
  //         : today.setFullYear(today.getFullYear() - 1);
  //       let _b = b.last_active
  //         ? new Date(b.last_active)
  //         : today.setFullYear(today.getFullYear() - 1);

  //       return _b - _a;
  //     });
  //   setSortedUser(sortedUser);
  // }, [props.users]);
  const onCellClick = (value) => {
    setOpen(true);
    setDeleteUserId(value);
  };

  const handleCancelConfirm = () => {
    setOpen(false);
    setDeleteUserId(null);
  };

  const navigate = useNavigate();

  const handleOpenPage = (id) => {
    navigate(`/dashboard/users/${id}`);
  };

  const openEditModal = (user, status) => {
    setOpenModal(status);
    setUserdata(user);
  };

  const [value, setValue] = useState("");
  const theme = useTheme();
  const columns = useMemo(
    () => columnShape(onCellClick, handleOpenPage, openEditModal),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: props.users,
      pageCount: num_pages,
      manualPagination: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  ); // handle change pagination

  const changeTab = (_, newValue) => setValue(newValue); // search value state
  const [loading, setLoading] = useState();

  const getUsers = async (
    page = 1,
    limit = 50,
    type = "active",
    search = ""
  ) => {
    setCurrentPage(page);
    setLimit(limit);
    setLoading(true);
    const users = await UserService.getUsers(page, limit, type, search);
    props.getUsers(users.data);
    setNumPages(users.num_pages);
    setVendor_count(users.vendor_counts);
    setLoading(false);
  };

  useEffect(() => {
    getUsers(currentPage, limit, option, searchValue);
  }, [profileReloaded]);

  const handleDeleteUser = async () => {
    const resp = await UserService.DeleteUser(deleteUserId);
    setOpen(false);
    getUsers();
  };

  const handleChange = (_, currentPageNo) => handlePageChange(currentPageNo);

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    getUsers(page, limit, option, searchValue);
  };

  const handleLimitChange = (event) => {
    // setCurrentPage(1);
    // setLimit(event.target.value);
    getUsers(1, event.target.value, option, searchValue);
  };

  const handleTypeChange = (event) => {
    // setCurrentPage(1);
    // setLimit(50);
    setOption(event.target.value);
    getUsers(1, 50, event.target.value, searchValue);
    setSearchValue(searchValue);
  };

  const handleSearch = (e) => {
    setSearchValue(e);
    // setCurrentPage(1);
    getUsers(1, 50, option, searchValue);
  };

  return (
    <Box pt={2} pb={4}>
      <Card
        sx={{
          py: 2,
        }}
      >
        <Box px={3}>
          <HeadingArea
            getUsers={getUsers}
            value={value}
            changeTab={changeTab}
            users={props.users}
            vendor_count={vendor_count}
            option={option}
            handleTypeChange={handleTypeChange}
          />

          {/* <SearchArea
            value={searchValue}
            onChange={handleSearch}
            setValue={setSearchValue}
            selectedItems={selectedFlatRows}
            gridRoute="/dashboards/user-grid"
            listRoute="/dashboards/user-list"
          /> */}
          <SearchInput
            // disable_border
            value={searchValue}
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        <Scrollbar autoHide={false}>
          <Table
            {...getTableProps()}
            sx={{
              minWidth: 900,
            }}
          >
            <TableHead
              sx={{
                borderBottom: `2px solid ${theme.palette.divider}`,
              }}
            >
              {headerGroups.map((headerGroup, index) => (
                <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <HeadTableCell
                      key={index}
                      // {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </HeadTableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {loading ? (
                <StyledTableCell colSpan={8} align="center">
                  <CircularProgress />
                </StyledTableCell>
              ) : arrayIsEmpty(props.users) ? (
                <StyledTableCell colSpan={8} align="center">
                  <NoResultFound />
                </StyledTableCell>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  const { _id, role, is_deleted } = row.original;

                  return (
                    // role == "admin" &&
                    // !is_deleted &&
                    <TableRow
                      {...row.getRowProps()}
                      key={index}
                      // onClick={() => handleOpenPage(_id)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {row.cells.map((cell, index) => {
                        if (index === 1) {
                          return (
                            <BodyTableCell
                              sx={{
                                minWidth: "160px",
                              }}
                              key={index}
                              {...cell.getCellProps()}
                              onClick={() => handleOpenPage(_id)}
                            >
                              {cell.render("Cell")}
                            </BodyTableCell>
                          );
                        }
                        // if (index === 0) {
                        //   return (
                        //     <BodyTableCell
                        //       sx={{
                        //         minWidth: "160px",
                        //       }}
                        //       key={index}
                        //       {...cell.getCellProps()}
                        //       onClick={() => handleOpenPage(_id)}
                        //     >
                        //       {cell.render("Cell")}
                        //     </BodyTableCell>
                        //   );
                        // }
                        else {
                          return (
                            <BodyTableCell
                              // sx={{
                              //   minWidth: "160px",
                              // }}
                              key={index}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </BodyTableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
            <DeleteDialog
              open={open}
              title={"Confirm Delete"}
              subTitle={"It will delete this vendor permanently. Are you sure?"}
              handleCancel={handleCancelConfirm}
              handleConfirm={() => handleDeleteUser()}
            />

            {userdata && (
              <AddEmployeeModal
                onSuccess={getUsers}
                data={userdata}
                edit={true}
                discord={true}
                open={openModal}
                onClose={() => setOpenModal(false)}
              />
            )}
          </Table>
        </Scrollbar>

        <PaginanationAndLimit
          tableData={props.users}
          handleChange={handleChange}
          count={pageOptions.length}
          currentPage={currentPage}
          handleLimitChange={handleLimitChange}
          limit={limit}
        />
      </Card>
    </Box>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    users: user.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { getUsers: (user) => dispatch(getUsers(user)) };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserListView);
