import reactStringReplace from "react-string-replace";
import PaxfulProfilePic from "./PaxfulProfilePic";
import { Box } from "@mui/material";
import { Small } from "components/Typography";
import Platform from "./Platform";

export const replaceNotificationTextTags = (text, trade, buyer) => {
  // console.log("__Buyer", buyer);
  //-- replace [TRADE_HASH] with trade link
  let message = reactStringReplace(
    text,
    "[TRADE_HASH]",
    () => trade?.paxful_trade_hash
  );

  //replace [BUYER] with partner's profile
  message = reactStringReplace(message, "[BUYER]", () => (
    <>
      {/* <Small mx={0.5}>
        <PaxfulProfilePic
          size={15}
          account={buyer.profile}
          buyer={true}
          className="bg-light"
        />
      </Small> */}
      <Box
        sx={{
          display: "block",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "inline",
            marginRight: "4px",
          }}
        >
          <PaxfulProfilePic
            size={18}
            account={buyer.profile}
            buyer={true}
            className="bg-light"
          />
          <Box
            sx={{
              position: "absolute",
              top: -10,
              bottom: 0,
              left: 18,
              right: 0,
            }}
          >
            <Platform platform={buyer.platform} size={14} />
          </Box>
        </Box>
        <span className="ms-2">{buyer.username}</span>
      </Box>
    </>
  ));

  message = reactStringReplace(message, "[CRYPTO]", () => (
    <>
      {trade?.trade?.crypto_currency_code}{" "}
      <img
        src={`/media/svg/coins/${trade?.trade?.crypto_currency_code.toLowerCase()}.svg`}
        style={{ width: "14px", height: "14px" }}
      />
    </>
  ));

  return message;
};
