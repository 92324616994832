import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, ContentCopy } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import PaxfulAccount from "components/shared/PaxfulAccount";
import DeleteButton from "page-sections/profiles/profile/DeleteButton";
import styled from "@emotion/styled";
import WebhookActionButton from "components/WebhookActionButton";
import ATooltip from "components/shared/ATooltip";
import FlexBox from "components/flexbox/FlexBox";
import notification from "helpers/notifications";

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};
const VendorAccountColumnShape = (
  navigate,
  confirmDeleteAccount,
  onCellClick
) => {
  return [
    {
      Header: "ID",
      accessor: "_id",
      Cell: ({ value }) => (
        <>
          <Box
            sx={{
              maxWidth: "40px",
            }}
          >
            <ATooltip content={value}>
              <Span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  copyTextToClipboard(value);
                }}
              >
                {value.slice(0, 2)}...
                {value.slice(value.length - 3, value.length)}
              </Span>
            </ATooltip>
          </Box>
        </>
      ),
    },
    {
      Header: () => <Box sx={{ marginLeft: "12px" }}>DATE </Box>,
      accessor: "createdAt",
      Cell: ({ value }) => (
        <Paragraph fontSize={13} color="text.secondary">
          <FormattedDateTime date={value} />
        </Paragraph>
      ),
    },
    {
      Header: () => <Box sx={{ marginLeft: "12px" }}>ACCOUNT </Box>,
      accessor: "account",
      Cell: ({ row }) => (
        <>
          <PaxfulAccount item={row.original} size={25} />
        </>
      ),
    },
    {
      Header: () => <Box sx={{ marginLeft: "12px" }}> VENDOR </Box>,
      accessor: "user",
      Cell: ({ value }) => {
        // console.log("value", value._id);
        return (
          <Stack direction="row" spacing={2} alignItems={"center"}>
            {value && <UserSymbol user={value} size={25} />}

            <Box
              sx={{
                marginLeft: "10px !important",
              }}
            >
              <Paragraph
                fontSize={13}
                color="text.secondary"
                // onClick={() => navigate(`/dashboard/users/${value._id}`)}
              >
                <Link to={`/dashboard/users/${value._id}`}>
                  {value == null
                    ? "-"
                    : value.first_name + " " + value.last_name}
                </Link>
              </Paragraph>
            </Box>
          </Stack>
        );
      },
    },
    {
      Header: () => <Box sx={{ marginLeft: "10px" }}>API KEY</Box>,
      accessor: "paxful_api_key",
      Cell: ({ row }) => {
        const { paxful_api_key, was_delegate, is_delegate } = row.original;
        return (
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Tiny fontSize={12} fontWeight={500}>
              {is_delegate || was_delegate
                ? "Connected By Authflow"
                : paxful_api_key}
            </Tiny>
          </Stack>
        );
      },
    },

    {
      Header: "ACTION",
      accessor: "is_deleted",
      Cell: ({ row }) => {
        return (
          <>
            <FlexBox sx={{ alignItems: "end", justifyContent: "end" }}>
              {!row.original.is_delegate && (
                <>
                  <ATooltip content={"Delete Webhook"}>
                    <WebhookActionButton
                      color="error"
                      onClick={() =>
                        confirmDeleteAccount(row.original._id, "delete")
                      }
                    />
                  </ATooltip>
                  <ATooltip content={"Reset Webhook"}>
                    <WebhookActionButton
                      color="primary"
                      onClick={() =>
                        confirmDeleteAccount(row.original._id, "reset")
                      }
                    />
                  </ATooltip>
                </>
              )}
              <ATooltip content={"Delete Account"}>
                {row.original.is_deleted ? null : (
                  <DeleteButton
                    onClick={() => confirmDeleteAccount(row.original._id, null)}
                  />
                )}
              </ATooltip>
            </FlexBox>
          </>
        );
      },
    },
  ];
};
export default VendorAccountColumnShape;
