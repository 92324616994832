const cryptoAssets = [
  {
    title: "Bitcoin",
    code: "BTC",
    assetID: "BTC",
    blockchain: "bitcoin",
    nativeAsset: "BTC",
    rate: "BTC",
    showOnWallet: true,
    //network: 'Bitcoin',
    decimals: 8,
    fee: "BTC",
    noones: true,
    paxful: true,
    explorerLink: `https://www.blockchain.com/explorer/transactions/btc/`,
    addressLink: `https://www.blockchain.com/explorer/addresses/btc`,
    token: "BTC",
    confirmations: 1,
    nativeAssetTitle: "Bitcoin",
    icon: "btc",
    addressAsset: "BTC",
    networkName: "Bitcoin",
    order: 1,
  },
  {
    title: "Ethereum",
    code: "ETH",
    assetID: "ETH",
    blockchain: "ethereum",
    nativeAsset: "ETH",
    rate: "ETH",
    showOnWallet: true,
    //network: 'ERC-20',
    decimals: 9, //18
    fee: "ETH",
    noones: true,
    paxful: true,
    explorerLink: `https://etherscan.io/tx/`,
    addressLink: `https://etherscan.io/address`,
    confirmations: 4,
    nativeAssetTitle: "Ethereum",
    icon: "eth",
    addressAsset: "ETH",
    networkName: "ERC-20",
    order: 2,
  },

  {
    title: "Tether",
    code: "USDT",
    assetID: "USDT_ERC20",
    nativeAsset: "ETH",
    rate: "USDT",
    blockchain: "ethereum",
    showOnWallet: true,
    network: "ERC-20",
    decimals: 6,
    fee: "USDT",
    noones: true,
    paxful: true,
    explorerLink: `https://etherscan.io/tx/`,
    addressLink: `https://etherscan.io/address`,
    confirmations: 4,
    nativeAssetTitle: "Ethereum",
    icon: "usdt",
    addressAsset: "ETH",
    networkName: "ERC-20",
    order: 3,
  },
  {
    title: "USD Coin",
    code: "USDC",
    assetID: "USDC",
    blockchain: "ethereum",
    nativeAsset: "ETH",
    rate: "USDC",
    showOnWallet: true,
    network: "ERC-20",
    decimals: 6,
    noones: true,
    paxful: true,
    explorerLink: `https://etherscan.io/tx/`,
    addressLink: `https://etherscan.io/address`,
    confirmations: 4,
    nativeAssetTitle: "Ethereum",
    icon: "usdc",
    addressAsset: "ETH",
    networkName: "ERC-20",
    order: 4,
  },
  {
    title: "BNB",
    code: "BNB",
    assetID: "BNB_BSC",
    blockchain: "bscscan",
    nativeAsset: "BNB_BSC", //
    rate: "BNB",
    showOnWallet: true,
    network: "BSC",
    decimals: 9, //18
    explorerLink: `https://bscscan.com/tx/`,
    addressLink: `https://bscscan.com/address`,
    confirmations: 1,
    nativeAssetTitle: "Binance Smart Chain",
    icon: "bnb",
    addressAsset: "BNB",
    networkName: "BEP-20",
    order: 5,
  },
  {
    title: "TRON",
    code: "TRX",
    assetID: "TRX",
    blockchain: "tron",
    nativeAsset: "TRX",
    rate: "TRX",
    showOnWallet: true,
    //network: 'TRC-20',
    fee: "TRX",
    decimals: 6,
    explorerLink: `https://tronscan.org/#/transaction/`,
    addressLink: `https://tronscan.org/#/address`,
    confirmations: 1,
    nativeAssetTitle: "Tron",
    icon: "trx",
    addressAsset: "TRX",
    networkName: "TRC-20",
    order: 6,
  },
  {
    title: "Solana",
    code: "SOL",
    assetID: "SOL",
    nativeAsset: "SOL",
    rate: "SOL",
    blockchain: "solana",
    showOnWallet: true,
    decimals: 8,
    fee: "SOL",
    explorerLink: `https://explorer.solana.com/tx/`,
    addressLink: `https://explorer.solana.com/address`,
    confirmations: 1,
    nativeAssetTitle: "Solana",
    icon: "sol",
    addressAsset: "SOL",
    networkName: "Solana",
    order: 7,
  },
  {
    title: "Ripple",
    code: "XRP",
    assetID: "XRP",
    nativeAsset: "XRP",
    rate: "XRP",
    blockchain: "xrpscan",
    showOnWallet: true,
    //network: 'Ripple',
    decimals: 6,
    fee: "XRP",
    explorerLink: `https://xrpscan.com/tx/`,
    addressLink: `https://xrpscan.com/account`,
    confirmations: 1,
    nativeAssetTitle: "Ripple",
    icon: "xrp",
    addressAsset: "XRP",
    networkName: "Ripple",
    order: 8,
  },
  {
    title: "Litecoin",
    code: "LTC",
    assetID: "LTC",
    nativeAsset: "LTC",
    rate: "LTC",
    blockchain: "litecoin",
    showOnWallet: true,
    //network: 'LTC-20',
    decimals: 8,
    fee: "LTC",
    explorerLink: `https://explorer.litecoin.net/tx/`,
    addressLink: `https://explorer.litecoin.net/address`,
    confirmations: 1,
    nativeAssetTitle: "Litecoin",
    icon: "ltc",
    addressAsset: "LTC",
    networkName: "LTC-20",
    order: 9,
  },
  {
    title: "Dogecoin",
    code: "DOGE",
    assetID: "DOGE",
    nativeAsset: "DOGE",
    rate: "DOGE",
    blockchain: "dogecoin",
    showOnWallet: true,
    decimals: 8,
    fee: "DOGE",
    explorerLink: `https://dogechain.info/tx/`,
    addressLink: `https://dogechain.info/address`,
    confirmations: 10,
    nativeAssetTitle: "Dogecoin",
    icon: "doge",
    addressAsset: "DOGE",
    networkName: "Dogecoin",
    order: 10,
  },
  {
    title: "Cardano",
    code: "ADA",
    assetID: "ADA",
    nativeAsset: "ADA",
    rate: "ADA",
    blockchain: "cordano",
    showOnWallet: true,
    decimals: 8,
    fee: "ADA",
    explorerLink: `https://explorer.cardano.org/en/transaction?id=`,
    addressLink: `https://explorer.cardano.org/en/address.html?address=`,
    confirmations: 1,
    nativeAssetTitle: "Cardano",
    icon: "ada",
    addressAsset: "ADA",
    networkName: "Cardano",
    order: 11,
  },
  {
    title: "Stellar",
    code: "XLM",
    assetID: "XLM",
    blockchain: "stellar",
    nativeAsset: "XLM",
    rate: "XLM",
    showOnWallet: true,
    //network: 'Stellar',
    decimals: 7,
    fee: "XLM",
    explorerLink: `https://stellarchain.io/transactions/`,
    addressLink: `https://stellarchain.io/accounts`,
    confirmations: 1,
    nativeAssetTitle: "Stellar",
    icon: "xlm",
    addressAsset: "XLM",
    networkName: "Stellar",
    order: 12,
  },
  {
    title: "Tether",
    code: "USDT",
    assetID: "TRX_USDT_S2UZ",
    nativeAsset: "TRX",
    rate: "USDT",
    blockchain: "tron",
    showOnWallet: false,
    network: "TRC-20",
    decimals: 6,
    fee: "USDT",
    noones: true,
    paxful: true,
    explorerLink: `https://tronscan.org/#/transaction/`,
    addressLink: `https://tronscan.org/#/address`,
    confirmations: 3,
    nativeAssetTitle: "Tron",
    icon: "usdt",
    addressAsset: "TRX",
    networkName: "TRC-20",
    order: 13,
  },
  {
    title: "USD Coin",
    code: "USDC",
    assetID: "USDC_BSC",
    nativeAsset: "BNB_BSC",
    rate: "USDC",
    blockchain: "bscscan",
    showOnWallet: true,
    network: "BEP-20",
    decimals: 6,
    fee: "USDC",
    explorerLink: `https://etherscan.io/tx/`,
    addressLink: `https://etherscan.io/address`,
    confirmations: 4,
    nativeAssetTitle: "Binance Smart Chain",
    icon: "usdc",
    addressAsset: "BNB",
    networkName: "BEP-20",
    order: 14,
  },

  {
    title: "DAI",
    code: "DAI",
    assetID: "DAI",
    blockchain: "ethereum",
    nativeAsset: "ETH",
    rate: "DAI",
    showOnWallet: true,
    network: "ERC-20",
    decimals: 9, //18
    explorerLink: `https://etherscan.io/tx/`,
    addressLink: `https://etherscan.io/address`,
    confirmations: 4,
    nativeAssetTitle: "Ethereum",
    icon: "dai",
    addressAsset: "ETH",
    networkName: "ERC-20",
    order: 15,
  },

  {
    title: "USD Coin",
    code: "USDC",
    assetID: "TRX_USDC_6NU3",
    blockchain: "tron",
    nativeAsset: "TRX",
    rate: "USDC",
    showOnWallet: false,
    network: "TRC-20",
    decimals: 6,
    explorerLink: `https://tronscan.org/#/transaction/`,
    addressLink: `https://tronscan.org/#/address`,
    confirmations: 3,
    nativeAssetTitle: "Tron",
    icon: "usdc",
    addressAsset: "TRX",
    networkName: "TRC-20",
    order: 16,
  },

  {
    title: "Tether",
    code: "USDT",
    assetID: "USDT_BSC",
    nativeAsset: "BNB_BSC",
    rate: "USDT",
    blockchain: "bscscan",
    showOnWallet: true,
    network: "BEP-20",
    decimals: 6,
    fee: "USDT",
    explorerLink: `https://etherscan.io/tx/`,
    addressLink: `https://etherscan.io/address`,
    confirmations: 4,
    nativeAssetTitle: "Binance Smart Chain",
    icon: "usdt",
    addressAsset: "BNB",
    networkName: "BEP-20",
    order: 17,
  },

  {
    title: "Chainlink",
    code: "LINK",
    assetID: "LINK",
    blockchain: "ethereum",
    nativeAsset: "ETH",
    rate: "LINK",
    showOnWallet: true,
    network: "ERC-20",
    decimals: 8,
    explorerLink: `https://etherscan.io/tx/`,
    addressLink: `https://etherscan.io/address`,
    confirmations: 4,
    nativeAssetTitle: "Ethereum",
    icon: "link",
    addressAsset: "ETH",
    networkName: "ERC-20",
    order: 18,
  },
  {
    title: "Wrapped Bitcoin",
    code: "WBTC",
    assetID: "WBTC",
    blockchain: "ethereum",
    nativeAsset: "ETH",
    rate: "BTC",
    showOnWallet: true,
    network: "ERC-20",
    decimals: 8,
    explorerLink: `https://etherscan.io/tx/`,
    addressLink: `https://etherscan.io/address`,
    confirmations: 4,
    nativeAssetTitle: "Ethereum",
    icon: "wbtc",
    addressAsset: "ETH",
    networkName: "ERC-20",
    order: 19,
  },
  // {
  //   title: "Wrapped Ethereum",
  //   code: "WETH",
  //   assetID: "WETH",
  //   blockchain: "ethereum",
  //   nativeAsset: "ETH",
  //   rate: "ETH",
  //   showOnWallet: true,
  //   network: "ERC-20",
  //   decimals: 9, //18
  //   explorerLink: `https://etherscan.io/tx/`,
  //   addressLink: `https://etherscan.io/address`,
  //   confirmations: 4,
  //   nativeAssetTitle: "Ethereum",
  //   icon: "weth",
  //   addressAsset: "ETH",
  //   networkName: "ERC-20",
  //   order: 20,
  // },
  {
    title: "Uniswap",
    code: "UNI",
    assetID: "UNI",
    blockchain: "ethereum",
    nativeAsset: "ETH",
    rate: "UNI",
    showOnWallet: true,
    network: "ERC-20",
    decimals: 8,
    explorerLink: `https://etherscan.io/tx/`,
    addressLink: `https://etherscan.io/address`,
    confirmations: 4,
    nativeAssetTitle: "Ethereum",
    icon: "uni",
    addressAsset: "ETH",
    networkName: "ERC-20",
    order: 21,
  },
];

export default cryptoAssets;
