import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  styled,
  Table,
  TableRow,
  useTheme,
} from "@mui/material";
import { Button } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import FlexBetween from "components/flexbox/FlexBetween";
import { H5, Paragraph } from "components/Typography";
import IconWrapper from "components/IconWrapper";
import GroupSenior from "icons/GroupSenior";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import AppPagination from "components/AppPagination";
import Scrollbar from "components/ScrollBar";
import BlankCheckBoxIcon from "icons/BlankCheckBoxIcon";
import CheckBoxIcon from "icons/CheckBoxIcon";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import columnShape from "./ColumnShape";
import HeadingArea from "page-sections/user-list/heading-area";
import { useCallback, useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import { connect } from "react-redux";
import TradeServices from "services/TradeService";
import { LoadingButton } from "@mui/lab";

import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import {
  useExpanded,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { getUsers } from "redux/actions/user";
import NoResultFound from "components/shared/NoResultFound";
import { arrayIsEmpty } from "utils/utils";
import Platform from "components/shared/Platform";
import PaxfulAccountsService from "services/PaxfulAccountsService";
import { AccountSelect } from "../offer/Offers";
import PaginanationAndLimit from "components/shared/PaginanationAndLimit";
import { useLocation, useNavigate } from "react-router-dom";
import navigations from "layouts/layout-parts/navigation";
import SearchInput from "components/input-fields/SearchInput";
import { debounce } from "lodash";
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 13,
  fontWeight: 500,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const SelectCheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    if (resolvedRef) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);
  return (
    <Checkbox
      {...rest}
      disableRipple
      ref={resolvedRef}
      icon={<BlankCheckBoxIcon fontSize="small" color="disabled" />}
      checkedIcon={<CheckBoxIcon fontSize="small" color="primary" />}
    />
  );
});
const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const TradeListView = (props) => {
  let navigate = useNavigate();
  let location = useLocation();

  const [value, setValue] = useState("");
  const theme = useTheme();

  const { userdata, trades, setTrades, tradeNumPage, setTradeNumPage } =
    useContext(UserContext);
  const columns = useMemo(() => columnShape(userdata), []);

  const [isLoading, setisLoading] = useState(false);
  const [account, setAccount] = useState("active");
  const [userAccounts, setUserAccounts] = useState([]);
  const [option, setOption] = useState("all");
  const [pageNum, setPageNum] = useState(1);
  const [limit, setLimit] = useState(50);

  const defaultColumn = useMemo(
    () => ({
      Filter: () => null, // Disable individual column filters
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: trades,
      pageCount: tradeNumPage,
      manualPagination: true,
      defaultColumn,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  ); // handle change pagination

  const handleChange = (_, currentPageNo) => gotoPage(currentPageNo - 1); // handle change for tab list

  const changeTab = (_, newValue) => setValue(newValue); // search value state

  const [searchValue, setSearchValue] = useState(state.globalFilter);
  // const handleSearch = useAsyncDebounce(
  //   (value) => setGlobalFilter(value || undefined),
  //   200
  // );

  const getTrades = async (
    id,
    account_id = "all",
    type = "active",
    page = 1,
    limit = 50,
    search = ""
  ) => {
    setisLoading(true);
    const resp = await TradeServices.getTrades(
      id,
      account_id,
      type,
      page,
      limit,
      search
    );
    if (resp.status) {
      setTrades(resp.data);
      setPageNum(page);
      setTradeNumPage(resp.num_pages);
    }
    setisLoading(false);
    console.log("Resp__", resp);
    window.scrollTo(0, 100);
  };

  // get User account
  const getUserPaxfulAccounts = async (id) => {
    const accounts = await PaxfulAccountsService.getUserPaxfulAccounts(
      id,
      "",
      ""
      // page,
      // limit
    );
    setUserAccounts(accounts.records);
  };
  //   console.log(trades);

  const reload = () => {
    setOption("all");
    setAccount("active");
    setSearchValue("");
    getTrades(userdata._id);
  };

  const selectHandleChange = (event) => {
    // setPageNum(1);
    setOption(event.target.value);
    getTrades(userdata._id, event.target.value, account, 1, limit, searchValue);
  };

  const handleTabChange = (event) => {
    // setPageNum(1);
    console.log("type", event.target.value);
    setAccount(event.target.value);
    console.log("type", event.target.value);
    getTrades(userdata._id, option, event.target.value, 1, limit, searchValue);
    // getPaxfulTransactions(userdata._id, pg, limit, option, event.target.value);
  };

  const handlePageChange = (event, page) => {
    setPageNum(page);
    getTrades(userdata._id, option, account, page, limit, searchValue);
  };

  const handleLimitChange = (event) => {
    setPageNum(1);
    setLimit(event.target.value);
    getTrades(
      userdata._id,
      option,
      account,
      1,
      event.target.value,
      searchValue
    );
  };
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      getTrades(userdata._id, option, account, 1, limit, searchValue);
      getUserPaxfulAccounts(userdata._id);
    }, 500),
    [limit, option]
  );

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
    setPageNum(1);
    debouncedSearch(searchValue);
  };

  useEffect(() => {
    getTrades(userdata._id, option, account, 1, 50, searchValue);
    getUserPaxfulAccounts(userdata._id);
    // return () => {
    //   console.log("helloo");
    //   const query = new URLSearchParams(location.search);
    //   const trade_hash = query.get("trade");
    //   console.log("trade hash: " + trade_hash);
    //   // Remove the 'trade' query parameter from the URL
    //   query.delete("trade");
    //   console.log("query", query.toString());

    //   navigate({
    //     search: query.toString(),
    //   });
    // };
  }, []);
  return (
    <Box pt={2} pb={4}>
      <Card
        sx={{
          py: 2,
        }}
      >
        <FlexBetween flexWrap="wrap" gap={1} mb={2}>
          <Box px={3}>
            {/* <FlexBox alignItems="center">
            <IconWrapper>
            <GroupSenior
                sx={{
                color: "primary.main",
                }}
            />
            </IconWrapper>
            <H5>{("Trades")}</H5>
        </FlexBox> */}
            {/* <HeadingArea value={value} changeTab={changeTab} /> */}

            <SearchInput
              placeholder="Search"
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>

          <StyledStack alignItems="center" direction="row" gap={2} px={2}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                sx={{
                  "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "10px !important", // Remove padding from the Select component
                    },
                }}
                onChange={(e) => handleTabChange(e)}
                value={account}
                disabled={option != "all"}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <Paragraph>All</Paragraph>
                </MenuItem>
                <MenuItem value={"active"}>
                  {" "}
                  <Paragraph>Active</Paragraph>{" "}
                </MenuItem>
                <MenuItem value={"delete"}>
                  {" "}
                  <Paragraph>Deleted</Paragraph>{" "}
                </MenuItem>
              </Select>
            </FormControl>

            <AccountSelect
              option={option}
              selectHandleChange={selectHandleChange}
              userAccounts={userAccounts}
            />
            <LoadingButton
              onClick={() => reload()}
              variant="contained"
              loading={isLoading}
              sx={{
                flexShrink: 0,
              }}
            >
              Reload
            </LoadingButton>
          </StyledStack>
        </FlexBetween>

        <Scrollbar autoHide={false}>
          <Table
            {...getTableProps()}
            sx={{
              minWidth: 900,
            }}
          >
            <TableHead
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              {headerGroups.map((headerGroup, index) => (
                <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <HeadTableCell
                      key={index}
                      // {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </HeadTableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {isLoading ? (
                <StyledTableCell colSpan={10} align="center">
                  <CircularProgress />
                </StyledTableCell>
              ) : arrayIsEmpty(trades) ? (
                <StyledTableCell colSpan={10} align="center">
                  <NoResultFound />
                </StyledTableCell>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => (
                        <BodyTableCell key={index} {...cell.getCellProps()}>
                          {cell.render("Cell", { onClick: props.onSelect })}
                        </BodyTableCell>
                      ))}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Scrollbar>

        {/* <Stack alignItems="center" marginY={4}>
          <AppPagination
            shape="rounded"
            onChange={handleChange}
            count={pageOptions.length}
          />
        </Stack> */}
        {!arrayIsEmpty(trades) && (
          <PaginanationAndLimit
            tableData={trades}
            handleChange={handlePageChange}
            count={pageOptions.length}
            currentPage={pageNum}
            handleLimitChange={handleLimitChange}
            limit={limit}
          />
        )}
      </Card>
    </Box>
  );
};
export default TradeListView;
