import {
  Badge,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import { FormattedDateTime, FromNow } from "components/shared/FormattedDate";
import { formatCryptoDecimal, formatCurrency } from "utils/currencies";
import FlexBox from "components/flexbox/FlexBox";
import CryptoImg from "components/shared/CryptoImg";
import cryptoAssets from "data/supportedAssets";
import { ArrowOutward } from "@mui/icons-material";
import ATooltip from "components/shared/ATooltip";
import { truncateText } from "utils/utils";
import { Link } from "react-router-dom";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import CreatedBy from "components/shared/CreatedBy";
import styled from "@emotion/styled";
import CardBrandIcon from "components/shared/CardBrandIcon";
import { UserContext } from "page-sections/user-list/ViewUser";
import { useContext } from "react";
import CryptoSymbol from "components/shared/CryptoSymbol";

const StyledButton = styled(Button)`
  minWidth: 60,
  marginRight: 3,
`;

const StyledLinkButton = styled(Link)(() => ({
  minWidth: 60,
  marginRight: 3,
  padding: "0.25rem",
  color: "primary.main",
  textDecoration: "none",
  border: "1px solid",
  borderColor: "primary.main",
  borderRadius: "4px",
  display: "inline-block",
  textAlign: "center",
}));
// const TransactionType = ({ transactionType, classification, network }) => {
//   if (network == "lightning") {
//     return "BTC Lightning Payment";
//   }
//   if (network == "paxfulpay") {
//     return "Paxful Wallet";
//   }
//   if (classification == "va") {
//     return "Hire Virtual Assistant";
//   }
//   if (classification == "trading") {
//     return "Trading Volume Topup";
//   }
//   if (classification == "subscription") {
//     return "Atlantis Membership";
//   }
//   return transactionType == "credit" ? "Deposit" : "Withdrawal";
// };

const TransactionType = ({ transactionType, classification, network }) => {
  if (classification == "va") {
    return "Hire Virtual Assistant";
  } else if (classification == "trading") {
    return "Trading Volume Topup";
  } else if (classification == "subscription") {
    return "Atlantis Membership";
  } else {
    return transactionType == "credit" ? "Deposit" : "Withdrawal";
  }
};

const getLightningDescription = (classification) => {
  switch (classification) {
    case "va":
      return (
        <>
          <Small fontSize={13} color="text.secondary">
            Paid via Lightning Network for Atlantis VA Services
          </Small>{" "}
        </>
      );
    case "trading":
      return (
        <>
          <Small fontSize={13} color="text.secondary">
            Paid via Lightning Network to topup Atlantis Trading Volume
          </Small>{" "}
        </>
      );
    case "subscription":
      return (
        <>
          <Small fontSize={13} color="text.secondary">
            Paid via Lightning Network for Atlantis Membership
          </Small>{" "}
        </>
      );
  }
};

const getPaxfulPayDescription = (classification) => {
  switch (classification) {
    case "va":
      return (
        <>
          <Small fontSize={13} color="text.secondary">
            Paid using Paxful Pay for Atlantis VA Services
          </Small>{" "}
        </>
      );
    case "trading":
      return (
        <>
          <Small fontSize={13} color="text.secondary">
            Paid using Paxful Pay to topup Atlantis Trading Volume
          </Small>{" "}
        </>
      );
    case "subscription":
      return (
        <>
          <Small fontSize={13} color="text.secondary">
            Paid using Paxful Pay for Atlantis Membership
          </Small>{" "}
        </>
      );
  }
};
const getCreditCardDescription = (classification, stripe_txn) => {
  let purpose = null;
  switch (classification) {
    case "va":
      purpose = "for Atlantis VA Services";
      break;
    case "trading":
      purpose = "to topup Atlantis Trading Volume";
      break;
    case "subscription":
      purpose = "for Atlantis Membership";
      break;
  }
  if (stripe_txn && stripe_txn.cardDetails) {
    return (
      <>
        <CardBrandIcon brand={stripe_txn.cardDetails.brand} /> Paid with card
        ending {stripe_txn.cardDetails.last4} {purpose}
      </>
    );
  }

  switch (classification) {
    case "va":
      return <>Paid with card ending 1234 for Atlantis VA Services</>;
    case "trading":
      return <>Paid with card ending 1234 to topup Atlantis Trading Volume</>;
    case "subscription":
      return <>Paid with card ending 1234 for Atlantis Membership</>;
  }
};

const getAccountPFP = ({ paxful_profile }) => {
  let account = JSON.parse(paxful_profile);
  return (
    <>
      <PaxfulProfilePic
        className={"me-1"}
        size={18}
        account={account}
        buyer={true}
      />
    </>
  );
};

function Badge2({ status, color }) {
  return (
    <div>
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".2rem 1rem",
          backgroundColor: color,
        }}
      >
        {status}
      </Small>
    </div>
  );
}

const getTxnStatus = (status) => {
  switch (status) {
    case "COMPLETED":
      return <Badge2 color="success.main" status="Completed" />;

    case "BLOCKED":
      return <Badge2 color="#fe9d2e" status="blocked" />;

    case "CANCELLED":
      return <Badge2 color="#ef282e" status="Cancelled" />;
    case "FAILED":
      return <Badge2 color="error.main" status="Failed" />;

    default:
      return <Badge2 color="success.main" status="Pending" />;
  }
};

const getTxnDescription = (status) => {
  switch (status) {
    case "BLOCKED":
      return (
        <Paragraph fontSize={13} color="text.secondary">
          Blocked
        </Paragraph>
      );
    case "CANCELLED":
      return (
        <Paragraph fontSize={13} color="text.secondary">
          Cancelled{" "}
        </Paragraph>
      );
    case "FAILED":
      return (
        <Paragraph fontSize={13} color="text.secondary">
          Failed{" "}
        </Paragraph>
      );
    default:
      return (
        <Paragraph fontSize={13} color="text.secondary">
          Pending
        </Paragraph>
      );
  }
};

const PurchaseDescription = ({
  user,
  asset,
  classification,
  txnHash,
  _id,
  created_by,
  transactionType,
}) => {
  const { userdata } = useContext(UserContext);
  console.log("colum,nshape userdata", userdata);

  let desc = null;
  if (classification == "va") {
    desc = "for Atlantis VA Services";
  }
  if (classification == "trading") {
    desc = "to topup Atlantis Trading Volume";
  }
  if (classification == "subscription") {
    desc = "for Atlantis Membership";
  }
  return (
    <>
      <FlexBox>
        {/* <CryptoImg
          width={18}
          currency={asset.code}
          size={18}
          className={"me-1"}
        /> */}
        <CryptoSymbol id={asset.assetID} />
        <Typography
          ml={1}
          fontSize={13}
          fontWeight={500}
          color="text.secondary"
        >
          {asset.title} Paid from Atlantis Wallet {desc}{" "}
        </Typography>
      </FlexBox>

      <FlexBox className="mt-2 d-flex align-items-center btn-wrap">
        <StyledLinkButton
          sx={{
            minWidth: 60,
            marginRight: 3,
            padding: "0.25rem",
            color: "primary.main",
            textDecoration: "none",
            border: "1px solid",
            borderColor: "primary.main",
            borderRadius: "4px",
            display: "inline-block",
            textAlign: "center",
          }}
          to={`/dashboard/users/${user}/transactions/${_id}`}
          state={{ additionalData: userdata }}
        >
          View Transaction
        </StyledLinkButton>
        {/* <ExternalLink
          className={"btn btn-xs btn-atlantis-primary ms-2"}
          link={asset.explorerLink + txnHash}
          label={"View on Explorer"}
        /> */}
        <StyledButton
          sx={{
            padding: "0.25rem",
            color: "primary.main",
            marginLeft: "10px",
          }}
          href={asset.explorerLink + txnHash}
          target="_blank"
          rel="noreferrer"
          variant="outlined"
          color="primary"
          size="small"
        >
          <span className="external-link-label">{"View on Explorer"}</span>
        </StyledButton>
        {transactionType == "debit" && created_by && (
          <Box ml={1}>
            <CreatedBy user={created_by} className="ms-2" />
          </Box>
        )}
      </FlexBox>
    </>
  );
};

const PaymentMethod = ({ network }) => {
  if (network == "lightning") {
    return "Bitcoin Lightning";
  } else if (network == "paxfulpay") {
    return "Paxful Pay";
  } else if (network == "cc") {
    return "Credit Card";
  } else {
    return "Crypto"; //Default to Crypto Wallet
  }
};

const ColumnShape = (userdata) => {
  return [
    {
      // Header: "DATE",
      Header: () => (
        <Box sx={{ minWidth: "120px", marginRight: "3rem" }}>DATE </Box>
      ),
      accessor: "createdAt",
      Cell: ({ value }) => (
        <Box>
          <Paragraph fontSize={13} color="text.secondary">
            <FormattedDateTime date={value} />
          </Paragraph>
          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#fff",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: "lightgrey",
              color: "black",
            }}
          >
            <FromNow date={value} />
          </Small>
        </Box>
      ),
    },
    {
      // Header: "METHODS",
      Header: () => <Box sx={{ minWidth: "100px" }}> PAYMENT METHODS</Box>,
      accessor: "METHODS",
      Cell: ({ row }) => {
        const { crypto_symbol } = row.original;
        return (
          <>
            <Small color="text.secondary" className="ms-5">
              <PaymentMethod {...row.original} />
            </Small>
          </>
        );
      },
    },
    {
      Header: () => (
        <Box
          sx={{ minWidth: "130px", marginRight: "1rem", marginLeft: "1.5rem" }}
        >
          TYPE
        </Box>
      ),
      accessor: "dd",
      Cell: ({ row }) => {
        const transaction = row.original;
        return (
          <Paragraph ml={2} fontSize={13} color="text.secondary">
            <TransactionType {...transaction} />
          </Paragraph>
        );
      },
    },
    {
      Header: "DETAILS",
      accessor: "txnHash",
      Cell: ({ row, value }) => {
        const {
          _id,
          transactionType,
          sourceAddressData,
          sourceAddress,
          destinationAddressData,
          destinationAddress,
          network,
          status,
          descriptor,
          classification,
          created_by,
          stripe_txn,
          assetID,
          sentByVendor,
          sentToVendor,
        } = row.original;
        console.log("userdata*/*/*/*/*/*", userdata);

        const asset = cryptoAssets.find(
          ({ assetID }) => row.original.assetId == assetID
        );
        let paymentDescription;
        if (network == "lightning") {
          paymentDescription = (
            <>
              <FlexBox
                sx={{
                  alignItems: "center",
                  marginLeft: "5rem",
                }}
              >
                <Box mr={1}>
                  <CryptoImg size={18} currency={"BTC"} className={"me-1"} />
                </Box>

                {getLightningDescription(classification)}
              </FlexBox>

              <FlexBox
                sx={{ alignItems: "center" }}
                className="mt-2 d-flex align-items-center"
                style={{ width: "fit-content" }}
              >
                <StyledLinkButton
                  sx={{
                    minWidth: 60,
                    marginRight: 3,
                    padding: "0.25rem",
                    color: "primary.main",
                    textDecoration: "none",
                    border: "1px solid",
                    borderColor: "primary.main",
                    borderRadius: "4px",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                  to={`/dashboard/users/${row.original.user}/transactions/${_id}`}
                  state={{ additionalData: userdata }}
                >
                  View Transaction
                </StyledLinkButton>
                {transactionType == "debit" && created_by && (
                  <Box ml={2} className="ms-2">
                    <CreatedBy user={created_by} />
                  </Box>
                )}
              </FlexBox>
            </>
          );
        } else if (network == "paxfulpay") {
          paymentDescription = (
            <>
              <FlexBox
                sx={{
                  alignItems: "center",
                }}
              >
                <Box mr={1}>
                  <CryptoImg size={18} currency={"BTC"} className={"me-1"} />
                </Box>

                {getPaxfulPayDescription(classification)}
              </FlexBox>

              <FlexBox
                sx={{ alignItems: "center" }}
                className="mt-2 d-flex align-items-center"
                style={{ width: "fit-content" }}
              >
                <StyledLinkButton
                  sx={{
                    minWidth: 60,
                    marginRight: 3,
                    padding: "0.25rem",
                    color: "primary.main",
                    textDecoration: "none",
                    border: "1px solid",
                    borderColor: "primary.main",
                    borderRadius: "4px",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                  to={`/dashboard/users/${row.original.user}/transactions/${_id}`}
                  state={{ additionalData: userdata }}
                >
                  View Transaction
                </StyledLinkButton>
                {transactionType == "debit" && created_by && (
                  <Box ml={2} className="ms-2">
                    <CreatedBy user={created_by} />
                  </Box>
                )}
              </FlexBox>
            </>
          );
        } else if (network == "cc") {
          paymentDescription = (
            <>
              <Small color="text.secondary">
                {getCreditCardDescription(classification, stripe_txn)}
              </Small>

              <FlexBox
                sx={{ alignItems: "center" }}
                className="mt-2 d-flex align-items-center"
                style={{ width: "fit-content" }}
              >
                {stripe_txn.invoiceUrl && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={stripe_txn.invoiceUrl}
                    style={{
                      border: "2px solid skyblue",
                      borderRadius: "5px",
                      padding: "3px 5px",
                    }}
                  >
                    <Small fontSize={11} fontWeight={600}>
                      {" "}
                      View Invoice
                    </Small>
                  </a>
                )}

                {transactionType == "debit" && created_by && (
                  <Box ml={2} className="ms-2">
                    <CreatedBy user={created_by} />
                  </Box>
                )}
              </FlexBox>
            </>
          );
        } else {
          getTxnDescription(row.original);
        }

        return (
          <>
            {value ? (
              <>
                {classification == "default" ? (
                  <>
                    <FlexBox>
                      {/* <CryptoImg
                        size={18}
                        currency={asset.code}
                        className={"me-1"}
                      /> */}
                      <CryptoSymbol id={asset.assetID} /> &nbsp;&nbsp;
                      <Small fontSize={13} color="text.secondary">
                        &nbsp;
                        {asset.title}
                        &nbsp;
                        {transactionType === "credit"
                          ? "Deposit into"
                          : "Withdrawal from"}{" "}
                        Atlantis Wallet{" "}
                      </Small>
                    </FlexBox>
                    {transactionType == "credit" ? (
                      <>
                        <Typography
                          fontSize={13}
                          color="text.secondary"
                          sx={{ maxWidth: "420px", overflowWrap: "break-word" }}
                        >
                          from{" "}
                          {sourceAddress && (
                            <>
                              <a
                                href={`${asset.addressLink}/${sourceAddress}`}
                                target="_blank"
                                rel="noreferrer"
                                className="transaction-address d-inline-flex align-items-center text-muted text-hover-primary"
                                fontSize={13}
                                color="text.secondary"
                                sx={{
                                  maxWidth: "420px",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {sourceAddress}
                                <span class="transaction-icon svg-icon svg-icon-muted svg-icon-3">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 -5 22 22"
                                    fill="none"
                                    // xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="16.9497"
                                      y="8.46448"
                                      width="13"
                                      height="2"
                                      rx="1"
                                      transform="rotate(135 16.9497 8.46448)"
                                      fill="currentColor"
                                    ></rect>
                                    <path
                                      d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                              </a>
                            </>
                          )}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Small fontSize={13} color="text.secondary">
                          to{" "}
                        </Small>
                        {destinationAddressData ? (
                          <>
                            {/* {destinationAddressData.accountData
                            ? getAccountPFP(destinationAddressData.accountData)
                            : null}
                          <span className="fw-bold">
                            {destinationAddressData.title}
                          </span>{" "}
                          <span className="text-muted">{destinationAddress}</span> */}
                            {destinationAddressData.account
                              ? getAccountPFP(destinationAddressData.account)
                              : null}{" "}
                            &nbsp;
                            {destinationAddressData.account && (
                              <span className="fw-bold">
                                {destinationAddressData.account.paxful_username}{" "}
                              </span>
                            )}
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              {destinationAddressData.title}
                              {destinationAddressData.account && (
                                <Box ml={1}>
                                  <Platform
                                    size={20}
                                    platform={
                                      destinationAddressData.account.platform
                                    }
                                    className={"w-20px ms-1"}
                                  />
                                </Box>
                              )}
                            </span>{" "}
                            <Paragraph
                              color="text.secondary"
                              className="text-muted"
                            >
                              {destinationAddress}
                            </Paragraph>
                          </>
                        ) : (
                          // <span className="text-muted">{destinationAddress}</span>
                          <>
                            <a
                              href={`${asset.addressLink}/${sourceAddress}`}
                              target="_blank"
                              rel="noreferrer"
                              className="transaction-address d-flex align-items-center text-muted text-hover-primary"
                            >
                              {destinationAddress}
                              <span class="transaction-icon svg-icon svg-icon-muted svg-icon-3">
                                <svg
                                  width="22"
                                  height="22"
                                  viewBox="0 -5 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.5"
                                    x="16.9497"
                                    y="8.46448"
                                    width="13"
                                    height="2"
                                    rx="1"
                                    transform="rotate(135 16.9497 8.46448)"
                                    fill="currentColor"
                                  ></rect>
                                  <path
                                    d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </span>
                            </a>
                          </>
                        )}
                      </>
                    )}{" "}
                    <FlexBox className="mt-2 d-flex align-items-center btn-wrap">
                      {/* <StyledButton
                        sx={{
                          padding: "0.25rem",
                          color: "primary.main",
                        }}
                        component={Link}
                        to={`/dashboard/users/${row.original.user}/transactions/${_id}`}
                        variant="outlined"
                        color="primary"
                        size="xs"
                      >
                        View Transaction
                      </StyledButton> */}
                      <StyledLinkButton
                        sx={{
                          minWidth: 60,
                          marginRight: 3,
                          padding: "0.25rem",
                          color: "primary.main",
                          textDecoration: "none",
                          border: "1px solid",
                          borderColor: "primary.main",
                          borderRadius: "4px",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                        to={`/dashboard/users/${row.original.user}/transactions/${_id}`}
                        state={{ additionalData: userdata }}
                      >
                        View Transaction
                      </StyledLinkButton>

                      {/* <Link href={`/wallet/transactions/${_id}`}>
                        <a className={"btn btn-xs btn-atlantis-primary"}>
                          View Transaction
                        </a>
                      </Link>{" "} */}
                      <StyledButton
                        sx={{
                          padding: "0.25rem",
                          color: "primary.main",
                          marginLeft: "5px",
                        }}
                        href={asset.explorerLink + value}
                        target="_blank"
                        rel="noreferrer"
                        variant="outlined"
                        color="primary"
                        size="small"
                      >
                        <span className="external-link-label">
                          {"View on Explorer"}
                        </span>
                      </StyledButton>

                      {/* <a
                        href={asset.explorerLink + value}
                        target="_blank"
                        rel={"noreferrer"}
                      >
                        <span className="external-link-label">
                          {"View on Explorer"}
                        </span>
                      </a> */}
                      {transactionType == "debit" && created_by && (
                        // Work on it
                        <Box ml={1}>
                          <CreatedBy user={created_by} className="ms-2" />
                        </Box>
                      )}

                      {sentToVendor && (
                        <div className="mt-1">
                          <Box ml={1}>
                            <CreatedBy
                              user={sentToVendor}
                              title={"Sent to vendor:"}
                              otherUser
                            />
                          </Box>
                        </div>
                      )}
                      {sentByVendor && (
                        <div className="mt-1">
                          <Box ml={1}>
                            <CreatedBy
                              user={sentByVendor}
                              title={"Deposit received from:"}
                              otherUser
                            />
                          </Box>
                        </div>
                      )}
                    </FlexBox>
                  </>
                ) : (
                  <PurchaseDescription
                    asset={asset}
                    transactionType={transactionType}
                    created_by={created_by}
                    {...row.original}
                  />
                )}

                {/* <a
                  href={asset.explorerLink + value}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <span className="external-link-label">
                    {"View Transaction"}
                  </span>
                </a> */}
              </>
            ) : (
              paymentDescription
            )}
          </>
        );
      },
    },
    ,
    {
      Header: "AMOUNT",
      accessor: "crypto_amount",
      Cell: ({ value, row, assetID }) => {
        const transactionType = row.original.transactionType;
        const crypto_symbol = row.original.crypto_symbol;
        const fiat_amount = row.original.fiat_amount;
        const { networkFee, crypto_amount, rate, network } = row.original;
        const asset = cryptoAssets.find(
          ({ assetID }) => row.original.assetId == assetID
        );

        const amount =
          transactionType == "credit"
            ? crypto_amount
            : crypto_amount + Number(networkFee);

        return (
          <>
            {network == "cc" ? (
              <Small className={`fw-bolder fs-5 text-gray-800`}>
                {formatCurrency(fiat_amount)} USD
              </Small>
            ) : (
              <Box fontSize={14} sx={{ textAlign: "start" }}>
                <FlexBox sx={{ alignItems: "center" }}>
                  <CryptoSymbol id={asset.assetID} size={18} /> &nbsp;
                  <Small ml={1}>
                    {/* {transactionType == "credit" ? "+" : "-"}{" "} */}
                    {formatCryptoDecimal(amount, asset?.decimals)}{" "}
                    {crypto_symbol}
                  </Small>
                  {/* <CryptoImg currency={crypto_symbol} size={15} /> */}
                </FlexBox>

                <Box>
                  <Paragraph fontSize={11} color={"text.secondary"}>
                    {formatCurrency(amount * rate)} USD
                  </Paragraph>
                </Box>
              </Box>
            )}
          </>
        );
      },
    },
    {
      Header: () => (
        <Box
          sx={{
            minWidth: "110px",
            marginRight: "0.2rem",
          }}
        >
          STATUS
        </Box>
      ),
      // Header: "STATUS",
      accessor: "status",
      Cell: ({ value }) => <Box>{getTxnStatus(value)} </Box>,
    },
  ];
};

export default ColumnShape;

// const TransactionCell = ({ row, value }) => {
//   const {
//     txnHash,
//     asset,
//     transactionType,
//     sourceAddressData,
//     sourceAddress,
//     destinationAddressData,
//     destinationAddress,
//     paymentDescription,
//   } = row;
//   return (
//     <>
//       {txnHash ? (
//         <>
//           <CryptoIcon width={18} crypto={asset.code} className={"me-1"} />
//           {asset.title}{" "}
//           {transactionType === "credit" ? "Deposit into" : "Withdrawal from"}{" "}
//           Atlantis Wallet{" "}
//           {transactionType === "credit" ? (
//             <>
//               from{" "}
//               {sourceAddressData ? (
//                 <>
//                   {sourceAddressData.accountData
//                     ? getAccountPFP(sourceAddressData.accountData)
//                     : null}
//                   {/* <span className="fw-bold">
//                       {sourceAddressData.title}
//                     </span>{" "} */}
//                   <span className="text-muted">{sourceAddress}</span>
//                 </>
//               ) : (
//                 <span className="text-muted">{sourceAddress}</span>
//               )}
//             </>
//           ) : (
//             <>
//               to{" "}
//               {destinationAddressData ? (
//                 <>
//                   {destinationAddressData.accountData
//                     ? getAccountPFP(destinationAddressData.accountData)
//                     : null}
//                   <span className="fw-bold">
//                     {destinationAddressData.title}
//                   </span>{" "}
//                   <span className="text-muted">{destinationAddress}</span>
//                 </>
//               ) : (
//                 <span className="text-muted">{destinationAddress}</span>
//               )}
//             </>
//           )}{" "}
//           <Link
//             href={asset.explorerLink + txnHash}
//             target="_blank"
//             rel="noopener"
//           >
//             View Transaction
//           </Link>
//         </>
//       ) : (
//         <Typography>{paymentDescription}</Typography>
//       )}
//     </>
//   );
// };
