import { combineReducers } from "@reduxjs/toolkit";

import user from "./user";
import webhooks from "./webhooks";
import paxfulAccount from "./paxfulAccount";

export default combineReducers({
  user,
  webhooks,
  paxfulAccount,
});
