import { Edit } from "@mui/icons-material";
import { IconButton, styled, Button, Box, Tooltip } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import FlexBox from "components/flexbox/FlexBox";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import { minWidth } from "@mui/system";
import Delete from "icons/Delete";
import LastActive from "components/shared/LastActive";
import OnlineStatus from "components/shared/OnlineStatus";
import MfaCheck from "components/shared/MfaCheck";
import AccountGroup from "components/shared/AccountGroup";
import CustomizedMenus from "components/MenuButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormattedDate } from "components/shared/FormattedDate";
import UserSymbol from "components/shared/UserSymbol";
import ATooltip from "components/shared/ATooltip";
import DiscordAvatar from "components/avatars/DiscordAvatar";
// import { copyTextToClipboard } from "utils/utils";
import ContentCopy from "@mui/icons-material/ContentCopy";
import notification from "helpers/notifications";
import RoleBadge from "utils/RoleBadge";
const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};

export const columnShape = (onCellClick, handleOpenPage, openEditModal) => {
  return [
    {
      Header: "ID",
      accessor: "_id",
      Cell: ({ value }) => (
        <>
          <Box
            sx={{
              maxWidth: "40px",
            }}
          >
            <ATooltip content={value}>
              <Span
                fontSize={14}
                // color={"black"}
                // fontWeight={500}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  copyTextToClipboard(value);
                }}
              >
                {value.slice(0, 2)}...
                {value.slice(value.length - 3, value.length)}
              </Span>
            </ATooltip>
          </Box>
        </>
      ),
    },
    {
      Header: "NAME",
      accessor: "name",
      Cell: ({ row }) => {
        const {
          first_name,
          last_name,
          name,
          email,
          email_verified,
          country,
          mobile_number,
          mobile_verified,
          account,
          role,
          plan,
          subscriptionEndsAt,
          subscriptionStartedAt,
          autoRenew,
          _id,
        } = row.original;
        let value = false;
        if (email != null) {
          // console.log("email true");

          value = true;
        }
        // console.log("subscriptionEndsAt", subscriptionEndsAt);
        // console.log("subscriptionStartedAt", subscriptionStartedAt);
        // console.log("_Row_", row);
        return (
          <>
            <FlexBox>
              <Box mt={1}>
                <UserSymbol user={row.original} size={30} />
              </Box>
              <Box ml={1}>
                <FlexRowAlign justifyContent="start">
                  <Small
                    color="text.secondary"
                    sx={{
                      fontWeight: "bold",
                      marginRight: "4px",
                    }}
                  >
                    {/* {first_name && first_name} {last_name && last_name} */}
                    {name}
                  </Small>

                  {country && (
                    <Span>
                      <CountryFlag code={country} width={"20px"} />
                    </Span>
                  )}
                  {plan && (
                    <ATooltip
                      content={
                        <>
                          {" "}
                          <Box px={2} py={1}>
                            {autoRenew ? (
                              <Paragraph fontSize={12} color={"text.secondary"}>
                                Renew on :{" "}
                                {<FormattedDate date={subscriptionEndsAt} />}
                              </Paragraph>
                            ) : (
                              <Paragraph fontSize={12} color={"text.secondary"}>
                                Active until :{" "}
                                {<FormattedDate date={subscriptionEndsAt} />}
                              </Paragraph>
                            )}
                          </Box>
                        </>
                      }
                    >
                      {/* <Small
                        ml={2}
                        sx={{
                          width: 100,
                          fontSize: 10,
                          borderRadius: "4px",
                          textAlign: "center",
                          padding: ".2rem 1rem",
                          backgroundColor:
                            plan !== "professional" ? "#00e5ff" : "#ffc107",
                        }}
                      >
                        {plan.charAt(0).toUpperCase() +
                          plan.slice(1).toLowerCase()}
                      </Small> */}
                    </ATooltip>
                  )}
                </FlexRowAlign>
                <FlexRowAlign justifyContent="start">
                  <Span
                    sx={{
                      color: "grey.400",
                      marginRight: "2px",
                    }}
                  >
                    {value ? email.replace(/<|>/g, "") : mobile_number}
                  </Span>
                  {value ? (
                    email_verified == true ? (
                      <StyledCheckMark />
                    ) : (
                      <StyledCancelIcon />
                    )
                  ) : mobile_verified == true ? (
                    <StyledCheckMark />
                  ) : (
                    <StyledCancelIcon />
                  )}
                </FlexRowAlign>

                {/* account */}
                <FlexBox
                  // ml={4}
                  sx={{ alignItems: "center" }}
                  justifyContent="start"
                >
                  <FlexBox
                    sx={{
                      marginRight: "0.8rem",
                    }}
                  >
                    <RoleBadge role={role} />
                  </FlexBox>
                  {account?.length > 0 && (
                    <FlexBox
                      sx={{
                        marginTop: "0.3rem",
                      }}
                    >
                      <AccountGroup accounts={account} />
                    </FlexBox>
                  )}
                </FlexBox>
              </Box>
            </FlexBox>
          </>
        );
      },
    },
    {
      Header: "JOIN DATE",
      accessor: "createdAt",
      Cell: ({ value }) => (
        <>
          <FormattedDate date={value} />
        </>
      ),
    },
    {
      Header: "LAST ACTIVE",
      accessor: "last_active",
      Cell: ({ row }) => {
        return (
          <>
            <FlexBox>
              <OnlineStatus member={row.original} is_live={false} />
            </FlexBox>
          </>
        );
      },
    },
    // {
    //   Header: "Phone",
    //   accessor: "phone_number",
    // },
    // {
    //   Header: "Paxful Status",
    //   accessor: "account_connected",
    //   Cell: ({ value }) => (
    //     <Small
    //       sx={{
    //         width: 100,
    //         fontSize: 10,
    //         color: "#fff",
    //         borderRadius: "4px",
    //         textAlign: "center",
    //         padding: ".2rem 1rem",
    //         backgroundColor: value ? "success.main" : "error.main",
    //       }}
    //     >
    //       {value ? "Connected" : "Not Connected"}
    //     </Small>
    //   ),
    // },

    {
      Header: "DISCORD",
      accessor: "account",
      Cell: ({ row }) => {
        const { discord } = row.original;

        return (
          <>
            {discord?.id && (
              <FlexBox sx={{ alignItems: "center" }} justifyContent="start">
                <FlexBox ml={1}>
                  <DiscordAvatar discordId={discord} width={30} />
                </FlexBox>
                <Box ml={1}>
                  {" "}
                  <Span>{discord.username}</Span>
                </Box>
              </FlexBox>
            )}
          </>
        );
      },
    },
    {
      Header: "TRADING STATUS",
      accessor: "trading_active",
      Cell: ({ value }) => (
        <FlexBox>
          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#fff",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: value ? "success.main" : "error.main",
            }}
          >
            {value ? "Active" : "Not Active"}
          </Small>
        </FlexBox>
      ),
    },

    {
      Header: "TWO-STEP",
      accessor: "mfa_enabled",
      Cell: ({ value }) => (
        <>
          <MfaCheck value={value} />
        </>
      ),
    },

    {
      Header: "ACTIONS",
      accessor: "actions",
      Cell: ({ row }) => {
        const { _id, is_deleted } = row.original;
        let arr = [
          {
            label: "View",
            onClick: () => handleOpenPage(_id),
            icon: <EditIcon />,
            is_delete: is_deleted,
          },
          {
            label: "Delete",
            onClick: () => onCellClick(_id),
            icon: <Delete />,
            is_delete: is_deleted,
          },
        ];
        return (
          <>
            {/* <StyledButton
              component={Link}
              to={`/dashboard/users/${_id}`}
              variant="outlined"
              color="primary"
              size="small"
            >
              View
            </StyledButton>

            <StyledButton
              sx={{
                ml: 2,
              }}
              onClick={() => onCellClick(_id)}
              variant="outlined"
              color="error"
              size="small"
            >
              Delete
            </StyledButton> */}
            <CustomizedMenus arr={arr} />
            {/* <IconButton>
            <Delete
              onClick={() => onCellClick(_id)}
              sx={{
                color: "text.disabled",
                fontSize: 18,
                marginLeft: "20px",
              }}
            />
          </IconButton> */}
            {/* <IconButton>
          <Edit
            sx={{
              color: "text.disabled",
              fontSize: 18,
            }}
          />
        </IconButton>  */}
          </>
        );
      },
    },
  ];
};

export default columnShape;
