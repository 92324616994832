import { React, useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  ListItem,
  Popover,
  Stack,
  styled,
  Tab,
  Table,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import TableBody from "@mui/material/TableBody";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCell from "@mui/material/TableCell";
import { alpha, Box } from "@mui/system";
import AppAvatar from "components/avatars/AppAvatar";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import Scrollbar from "components/ScrollBar";
import { H1, H4, H5, H6, Small, Tiny } from "components/Typography";
import InvertColors from "icons/InvertColors";
import KeyframeBezierIn from "icons/KeyframeBezierIn";
import PaletteOutlined from "icons/PaletteOutlined";
import { FormattedDate } from "components/shared/FormattedDate";
import { FromNow } from "components/shared/FormattedDate";
import EditButton from "../EditButton";

import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import Badge, { getRoleColor } from "utils/Badge";
import moment from "moment-timezone";
import LastActive from "components/shared/LastActive";
import UserSymbol from "components/shared/UserSymbol";
import AddEmployeeModal from "page-sections/data-table/dataTableV2/AddEmployeeModal";
import NoResultFound from "components/shared/NoResultFound";
import FlexBetween from "components/flexbox/FlexBetween";
import PasswordButton from "../PasswordButton";
import PasswordModal from "page-sections/data-table/PasswordModal";
import DeleteButton from "../DeleteButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import UserService from "services/UserService";
import FlexBox from "components/flexbox/FlexBox";
import GoogleIcon from "icons/GoogleIcon";
import Moment from "react-moment";
import OnlineStatus from "components/shared/OnlineStatus";
import MfaCheck from "components/shared/MfaCheck";
import ATooltip from "components/shared/ATooltip";
import LockButton from "./LockButton";
import Platform from "components/shared/Platform";
import SignInMethod from "components/shared/SignInMethod";

const GooglePicture = styled("img")`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

function TeamMemberRow({
  item,
  _handleChange,
  handleSelectedClick,
  confirmDeleteUser,
  changePassword,
  editUser,
  disableMFA,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const [open, setOpen] = useState(false);

  // Get data from usercontext api
  const { value, userdata, getUser } = useContext(UserContext);

  const [userToDelete, setUserToDelete] = useState(null);

  const [user, setUser] = useState(null);

  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelConfirm = () => {
    setOpen(false);
    setUserToDelete(null);
  };

  const handleDeleteUser = async () => {
    const resp = await UserService.DeleteUser(userToDelete);
    setOpen(false);
    getUser();
  };

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosed = () => {
    setAnchorEl(null);
  };

  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;
  const isActive = (date) => {
    const fiveMinutesAgo = moment().subtract(10, "minutes");
    const is_online = moment(date).isAfter(fiveMinutesAgo);
    return is_online;
  };

  return (
    <TableRow
      sx={{
        padding: "10px",
      }}
      key={id}
    >
      <StyledTableCell
        onClick={() => handleSelectedClick(item)}
        style={{
          cursor: "pointer",
        }}
      >
        <Stack direction="row" spacing={2}>
          <UserSymbol user={item} />

          <Box
            sx={{
              marginLeft: "10px !important",
            }}
          >
            <H6>{item.name}</H6>
            <Tiny fontSize={12} fontWeight={500}>
              {item.email == null ? item.mobile_number : item.email}
            </Tiny>

            <Box direction="row" spacing={2} display={"flex"}>
              <Badge role={item.role} />
              <Box ml={2}>
                {item.is_deleted ? (
                  <Small
                    sx={{
                      width: 100,
                      fontSize: 10,
                      color: "#fff",
                      borderRadius: "4px",
                      textAlign: "center",
                      padding: ".2rem 1rem",
                      backgroundColor: "error.main",
                    }}
                  >
                    DELETED
                  </Small>
                ) : (
                  <OnlineStatus member={item} is_live={false} />
                )}
              </Box>
            </Box>
          </Box>
        </Stack>
      </StyledTableCell>

      <StyledTableCell>
        <MfaCheck value={item.mfa_enabled} />
      </StyledTableCell>

      <StyledTableCell>
        {/* {item?.isGoogleSignup ? (
          <div>
            <Small
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              sx={{
                width: 100,
                fontSize: 10,
                color: "#fff",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor: "success.main",
              }}
            >
              {"Google SignIn"}
            </Small>

            <Popover
              id={id}
              open={opens}
              anchorEl={anchorEl}
              onClose={handleClosed}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <GooglePicture src={item?.googleId?.picture} alt="img" />
                <Box ml={1}>
                  <Tiny fontSize={12} fontWeight={600}>
                    Google
                  </Tiny>
                  <Tiny fontSize={12} fontWeight={500}>
                    {item.email}
                  </Tiny>
                </Box>
              </Box>
            </Popover>
          </div>
        ) : null} */}

        <FlexBox sx={{ alignItems: "center" }}>
          <Box mr={1}>
            {item.isDiscordSignup && (
              <ATooltip
                content={
                  <SignInMethod
                    user={item}
                    discordId={item.discord}
                    email={item.discord.username}
                  />
                }
              >
                {/* <DiscordAvatar discordId={discord} width={24} /> */}
                <img
                  src={"/media/logos/discord-logo-png-7617.png"}
                  width={"30px"}
                />
              </ATooltip>
            )}
          </Box>

          <Box mr={1}>
            {item.isGoogleSignup && (
              <ATooltip
                content={
                  <SignInMethod
                    user={item}
                    pfp={item.googleId?.picture}
                    email={item.googleId.email}
                  />
                }
              >
                <GoogleIcon
                  sx={{
                    width: "20px",
                  }}
                />
              </ATooltip>
            )}
          </Box>
          <Box mr={1}>
            {item.isPaxfulSignup && (
              <ATooltip
                content={
                  <SignInMethod
                    user={item}
                    pfp={item.paxfulId?.picture}
                    email={item.paxfulId?.email}
                  />
                }
              >
                <Platform platform={"paxful"} size={30} />
              </ATooltip>
            )}
          </Box>
          <Box mr={1}>
            {item.isNoonesSignup && (
              <ATooltip
                content={
                  <SignInMethod
                    user={item}
                    pfp={item.noonesId?.picture}
                    email={item.noonesId?.email}
                  />
                }
              >
                <Platform platform={"noones"} size={28} />
              </ATooltip>
            )}
          </Box>
        </FlexBox>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box>
          <FlexBox justifyContent="end">
            <ATooltip content={"Edit User"}>
              <EditButton onClick={() => editUser(item)} />
            </ATooltip>
            <ATooltip content={"Change Password"}>
              <PasswordButton onClick={() => changePassword(item)} />
            </ATooltip>
            {item.mfa_enabled && (
              <ATooltip content={"Disable MFA"}>
                <LockButton sx={{}} onClick={() => disableMFA(item)} />
              </ATooltip>
            )}
            <ATooltip content={"Delete User"}>
              {item.is_deleted ? null : (
                <DeleteButton onClick={() => confirmDeleteUser(item)} />
              )}
            </ATooltip>
          </FlexBox>
        </Box>
      </StyledTableCell>
    </TableRow>
  );
}

export default TeamMemberRow;
