import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Tab,
} from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5, Paragraph } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { io } from "socket.io-client";
import { LoadingButton, TabContext, TabList } from "@mui/lab";
import UserRequestTable from "page-sections/user-request";
import UserRequestApi from "services/userRequestService";
import BuyerTable from "page-sections/buyer";
import buyerService from "services/buyer";
import UserService from "services/UserService";
import UserMasterListAll from "page-sections/user-master-list/UserMasterList";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const TabListWrapper = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(727)]: {
    order: 3,
  },
}));

function UserMasterList() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState(INVOICE_LIST);
  //   const [tabValue, setTabValue] = useState("api");

  const [option, setOption] = useState("active");

  const [data, setData] = useState(null);

  const getUserMasterList = async (
    page = 1,
    limit = 50,
    type = "active",
    search = ""
  ) => {
    setisLoading(true);
    const resp = await UserService.getUserMasterList(page, limit, type, search);
    // let sortedUser =
    //   resp &&
    //   resp.data.sort(function (a, b) {
    //     let today = new Date();
    //     let _a = a.last_active
    //       ? new Date(a.last_active)
    //       : today.setFullYear(today.getFullYear() - 1);
    //     let _b = b.last_active
    //       ? new Date(b.last_active)
    //       : today.setFullYear(today.getFullYear() - 1);

    //     return _b - _a;
    //   });
    // resp.data = sortedUser;
    setPage(page);
    setData(resp);
    setisLoading(false);
  };

  useEffect(() => {
    getUserMasterList(1, 50, option, searchValue);
  }, []);

  const reload = () => {
    setPage(1);
    setLimit(50);
    setOption("active");
    setSearchValue("");
    getUserMasterList(1, 50, "active", "");
  };

  const handlePageChange = (page) => {
    // setPage(page);
    getUserMasterList(page, limit, option, searchValue);
  };



  const handleLimitChange = (event) => {
    const newLimit = event.target.value;
    const totalPages = Math.ceil(filteredItem.length / newLimit);
    const newPage = Math.min(page, totalPages); 
    
    setPage(newPage);
    setLimit(newLimit);
    getUserMasterList(newPage, newLimit, option, searchValue);
  };

  const handleChange = (event) => {
    // setPage(1);
    console.log("type", event);
    setOption(event.target.value);
    getUserMasterList(1, limit, event.target.value, searchValue);
  };
  const handleSearch = (searchValue) => {
    setSearchValue(searchValue)
    getUserMasterList(1, 50, option, searchValue);
  }

  return (
    <>
      <Box pt={2} pb={4}>
        <FlexBetween flexWrap="wrap" gap={1} mb={2}>
          <Stack direction="row" alignItems="center">
            <IconWrapper>
              <Invoice
                sx={{
                  color: "primary.main",
                }}
              />
            </IconWrapper>
            <H5>{t("User Master List")}</H5>
          </Stack>

          <StyledStack alignItems="center" direction="row" gap={2}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                sx={{
                  "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "10px !important", // Remove padding from the Select component
                    },
                }}
                value={option}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <Paragraph>All</Paragraph>
                </MenuItem>
                <MenuItem value={"active"}>
                  {" "}
                  <Paragraph>Active</Paragraph>{" "}
                </MenuItem>
                <MenuItem value={"delete"}>
                  {" "}
                  <Paragraph>Deleted</Paragraph>{" "}
                </MenuItem>
              </Select>
            </FormControl>
            <SearchInput
              // disable_border
              value={searchValue}
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <LoadingButton
              onClick={() => reload()}
              loading={isLoading}
              variant="contained"
              sx={{
                flexShrink: 0,
              }}
            >
              Reload
            </LoadingButton>
          </StyledStack>
        </FlexBetween>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            {" "}
            <CircularProgress />
          </Box>
        ) : (
          data && (
            <UserMasterListAll
              {...data}
              onPageChange={handlePageChange}
              currentPage={page}
              handleLimitChange={handleLimitChange}
              limit={limit}
              getUserMasterList={getUserMasterList}
            />
          )
        )}
      </Box>
    </>
  );
}

export default UserMasterList;
