import { ContentCopy, Edit } from "@mui/icons-material";
import { IconButton, styled, Button, Box, Tooltip } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import FlexBox from "components/flexbox/FlexBox";
import { Link } from "react-router-dom";
import { Tiny } from "components/Typography";
import { ArrowOutward, ArrowRight } from "@mui/icons-material";

import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import TradeStatus, { TradesStatus } from "components/shared/TradeStatus";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import BuyerProfilePic from "components/shared/BuyerProfilePic";
import PaxfulAccount from "components/shared/PaxfulAccount";
import { FlagAndPlatform } from "page-sections/profiles/profile/Trades/PartnerDetail";
import UserSymbol from "components/shared/UserSymbol";
import OfferType from "components/shared/OfferType";
import { formatCurrency } from "utils/currencies";
import CryptoBalance from "components/shared/CryptoBalance";
import FormattedBalance from "components/shared/FormattedBalance";
import TimeElapsedTimer from "components/TimeElapsedTimer";
import moment from "moment-timezone";
import Countdown from "react-countdown";
import ATooltip from "components/shared/ATooltip";
import notification from "helpers/notifications";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));

const countDownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (!completed) {
    return (
      <span className="fs-7">
        {hours > 0 ? `${hours}h` : ""} {minutes > 0 ? `${minutes}m` : ""}{" "}
        {seconds}s
      </span>
    );
  }
};
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};

const ActiveTradeColumnShape = [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
            marginRight: "10px",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "12px" }}>DATE </Box>,
    accessor: "createdAt",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        <FormattedDateTime date={value} />
      </Paragraph>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "14px" }}>VENDOR </Box>,
    accessor: "user",
    Cell: ({ value }) => {
      const user = value[0];
      return (
        <>
          <FlexBox sx={{ alignItems: "center" }}>
            <span>
              {" "}
              <UserSymbol user={user} size={25} />
            </span>

            <Box
              sx={{
                marginLeft: "10px !important",
              }}
            >
              <Link to={`/dashboard/users/${user._id}`}>
                {user.first_name + " " + user.last_name}
              </Link>
            </Box>
          </FlexBox>
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "11px" }}>TRADE HASH </Box>,
    accessor: "trade.trade_hash",
    Cell: ({ row, onClick }) => {
      const { user, account, trade } = row.original;
      const { _id } = user[0];
      return (
        <>
          {/* <Paragraph
            sx={{
              cursor: "pointer",
            }}
            color="primary.main"
            onClick={() => onClick(trade.trade_hash)}
          >
            {trade.trade_hash}
          </Paragraph>{" "} */}
          {/* <Paragraph fontSize={12} fontWeight={"bold"}>
            {trade.trade_hash}
          </Paragraph> */}
          <Link to={`/dashboard/users/${_id}/?trade=${trade.trade_hash}`}>
            {trade.trade_hash}
          </Link>
        </>
      );
    },
  },

  {
    Header: () => <Box sx={{ marginLeft: "12px" }}>ACCOUNT</Box>,
    accessor: "account.paxful_username",
    Cell: ({ row }) => {
      const { paxful_username, platform } = row.original.account;
      return <>{<PaxfulAccount item={row.original.account} size={25} />}</>;
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "8px" }}>PAYMENT METHOD </Box>,
    accessor: "trades",
    Cell: ({ row }) => {
      const { trade } = row.original;
      return (
        <FlexBox>
          <Paragraph mr={2} fontSize={12}>
            {" "}
            {trade.payment_method_name}
          </Paragraph>
          <OfferType value={trade.offer_type} />
        </FlexBox>
      );
    },
  },

  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>BUYER </Box>,
    accessor: "buyer",
    Cell: ({ row }) => {
      const { buyer, partner_location, account } = row.original;

      return (
        <FlexBox justifyContent="start" alignItems="center">
          <PaxfulProfilePic size={25} account={buyer[0].profile} buyer />
          {/* {buyer[0] && <BuyerProfilePic size={30} buyer={buyer[0]} />} */}
          {buyer[0].username && (
            <>
              <Tiny fontSize={12} fontWeight={500}>
                <a
                  style={{
                    marginLeft: "3px",
                  }}
                  href={
                    `https://${account.platform}.com/user/` + buyer[0].username
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {buyer[0].username}
                  {/* <ArrowOutward
                    sx={{
                      fontSize: 14,
                      marginLeft: "1px",
                    }}
                  /> */}
                </a>
              </Tiny>
              {partner_location && (
                <FlagAndPlatform
                  CountryName={
                    partner_location.detected_location.localized_name
                  }
                  country={partner_location.detected_location.iso}
                  platform={account.platform}
                />
              )}
            </>
          )}
        </FlexBox>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "8px" }}>FEE</Box>,
    accessor: "fbhdbf",
    Cell: ({ row }) => {
      let item = row.original;
      return (
        <>
          {item?.atlantis_fee_details?.atlantis_fee ? (
            <Paragraph color="text.secondary" fontSize={15} fontWeight="600">
              {formatCurrency(item.atlantis_fee_details.atlantis_fee)}{" "}
            </Paragraph>
          ) : (
            <Paragraph color="text.secondary" fontWeight="600" fontSize={15}>
              $0
            </Paragraph>
          )}
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>AMOUNT </Box>,
    accessor: "amount",
    Cell: ({ row }) => {
      let item = row.original;
      return (
        <Box
          sx={{
            maxWidth: "150px",
          }}
        >
          <Small fontWeight={"bold"}>
            <FormattedBalance
              offer_type={item.trade.offer_type}
              code={item.trade.fiat_currency_code}
              amount={item.trade.fiat_amount_requested}
              status={item.status}
              user={item.user[0]}
            />
          </Small>
          <span className="cursor-default text-gray-700">
            <CryptoBalance
              offer_type={item.trade.offer_type}
              code={item.trade.crypto_currency_code}
              amount={item.trade.crypto_amount_total}
              status={item.status}
              user={item.user[0]}
            />
          </span>
          {item.status !== "cancelled" && (
            <div className="fs-7 cursor-default">
              Profit:{" "}
              <Small color="success.main">
                {formatCurrency(
                  item.estimated_profit,
                  item.trade.fiat_currency_code
                )}
              </Small>
            </div>
          )}
        </Box>
      );
    },
  },

  {
    Header: "STATUS",
    accessor: "status",
    Cell: ({ row }) => {
      console.log("row.original.startedAt", row.original.startedAt);
      console.log(
        "row.original.payment_window",
        row.original.trade.payment_window
      );
      return (
        <Box
          sx={{
            minWidth: "110px",
          }}
        >
          <TradeStatus _trade={row.original} fontSize="10px" />
          <div className="time-wrap">
            {row.original.status === "new" && (
              <>
                <ATooltip content="Time Remaining">
                  <Small
                    fontSize={12}
                    fontWeight={600}
                    color="error.main"
                    className="text-danger fs-7 fw-bolder cursor-default"
                  >
                    <Countdown
                      date={
                        moment(row.original.startedAt)
                          .add(row.original.trade.payment_window, "minutes")
                          .unix() * 1000
                      }
                      renderer={countDownRenderer}
                    />
                  </Small>
                </ATooltip>
              </>
            )}
            {row.original.status === "paid" && (
              <>
                <ATooltip content="Time Elapsed">
                  <Small color="primary.main" fontSize={12} fontWeight={600}>
                    <TimeElapsedTimer
                      start={row.original.startedAt}
                      id={row.original._id}
                    />
                  </Small>
                </ATooltip>
              </>
            )}
          </div>
        </Box>
      );
    },
  },
];
export default ActiveTradeColumnShape;
