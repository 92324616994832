import React from "react";
import { H5, H6, Small, Tiny } from "components/Typography";
import { getRoleColor } from "utils/utils";
import { Box } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import { greyLight } from "theme/colors";
import AppAvatar from "components/avatars/AppAvatar";
import { COMMON_API_BASE_URL } from "config";

function UserSymbol({ user, size }) {
  return (
    <>
      {user && (user.profile_pic || user.social_profile_pic ) ? (
        <AppAvatar
          alt="Team Member"
          src={`${user.profile_pic
              ? `${COMMON_API_BASE_URL}/${user.profile_pic.file_path.md}`
              : `${user.social_profile_pic}`
            // "/static/avatar/001-man.svg"
            }`}
          sx={{
            width: size ? size : 35,
            height: size ? size : 35,
          }}
        />
      ) : (
        <FlexBox
          sx={{
            width: size ? size : 35,
            height: size ? size : 35,
            borderRadius: "50%",
            fontSize: size ? 10 : 16,
            color: "#fff",
            textAlign: "center",
            padding: "5px 0",
            backgroundColor: user?.role
              ? getRoleColor(user?.role)
              : "lightgrey",
            alignItems: "center",
            justifyContent: "center",
            color: user?.role ? "white" : "black",
          }}
        >
          {user?.first_name
            ? user?.first_name?.substr(0, 1).toUpperCase()
            : user?.email?.substr(0, 1).toUpperCase()}
        </FlexBox>
      )}
    </>
  );
}

export default UserSymbol;

// function UserSymbol({
//   user,
//   size,
//   circle,
//   fs,
//   fw,
//   className,
//   showOnline,
//   noWrapper,
//   team,
//   profile,
//   dialogOpen,
//   isUploading,
//   isDragActive,
//   isProfile,
//   imageSize,
//   isEditable,
//   onRemove,
//   onEdit,
//   onUploadPic,
// }) {
//   let color;
//   let status;
//   switch (user?.role) {
//     case "admin":
//       color = "info";
//       break;
//     case "employee":
//       color = "primary";
//       break;
//     case "manager":
//       color = "warning";
//       break;
//     case "trader":
//       color = "success";
//       break;
//   }

//   const _user = team ? team.find((member) => member._id == user._id) : null;

//   let profilePicPath = _user
//     ? _user.profile_pic?.file_path
//     : user?.profile_pic?.file_path;

//   let socialProfilePic = _user
//     ? _user.social_profile_pic
//     : user.social_profile_pic;

//   if (_user && _user.last_active) {
//     const fifteen_min_ago = moment().subtract(15, "minutes");
//     const away = moment().subtract(2, "days");

//     const is_online = moment(_user.last_active).isAfter(fifteen_min_ago);
//     const is_away = moment(_user.last_active).isAfter(away);

//     if (is_online) {
//       status = "success";
//     } else if (is_away) {
//       status = "warning";
//     } else {
//       status = "secondary";
//     }
//   } else {
//     status = "secondary";
//   }

//   //force va to show ionline always
//   if (user?.role === "trader") {
//     status = "success";
//   }

//   let Symbol = (
//     <>
//       {user && (profilePicPath || socialProfilePic) ? (
//         <>
//           <div className="user-avatar">
//             <img
//               className={`symbol-label bg--${color} text--inverse-${color} fw-${fw} fs-${fs}`}
//               src={
//                 profilePicPath
//                   ? `${API_BASE_URL}/${profilePicPath[imageSize]}`
//                   : socialProfilePic
//               }
//             />
//             {isEditable && (
//               <div className="icon_wrap">
//                 <Button
//                   className="edit text-hover-primary"
//                   size="sm"
//                   onClick={onEdit}
//                 >
//                   <span class="svg-icon svg-icon-muted svg-icon-2x">
//                     <svg
//                       width="24"
//                       height="24"
//                       viewBox="0 0 24 24"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         opacity="0.3"
//                         d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
//                         fill="currentColor"
//                       />
//                       <path
//                         d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
//                         fill="currentColor"
//                       />
//                     </svg>
//                   </span>
//                 </Button>
//                 <Button
//                   className="remove ms-1 text-hover-danger"
//                   size="sm"
//                   onClick={onRemove}
//                 >
//                   <span class="svg-icon svg-icon-muted  svg-icon-2x">
//                     <svg
//                       width="24"
//                       height="24"
//                       viewBox="0 0 24 24"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
//                         fill="currentColor"
//                       />
//                       <path
//                         opacity="0.5"
//                         d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
//                         fill="currentColor"
//                       />
//                       <path
//                         opacity="0.5"
//                         d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
//                         fill="currentColor"
//                       />
//                     </svg>
//                   </span>
//                 </Button>
//               </div>
//             )}
//           </div>

//           {showOnline && (
//             <span
//               className={`online-status position-absolute bg-${status} rounded-circle border border-1 h-8px w-8px `}
//             ></span>
//           )}
//         </>
//       ) : (
//         <>
//           <span
//             className={`symbol-label bg-${color} text-inverse-${color} fw-${fw} fs-${fs}`}
//           >
//             {user && user?.first_name?.slice(0, 1).toUpperCase()}
//           </span>

//           {showOnline && (
//             <span
//               className={`online-status position-absolute bg-${status} rounded-circle border border-1 h-8px w-8px `}
//             ></span>
//           )}
//         </>
//       )}
//     </>
//   );

//   return (
//     <>
//       {noWrapper ? (
//         Symbol
//       ) : (
//         <div
//           className={`symbol symbol-${size}px overflow--hidden${
//             circle ? " symbol-circle" : ""
//           } ${className ? className : ""} position-relative`}
//         >
//           {Symbol}
//           {(isDragActive || (isUploading && !dialogOpen)) && (
//             <div
//               id="drop-profile-pic"
//               className={
//                 isDragActive || (isUploading && !dialogOpen)
//                   ? "active"
//                   : "hidden"
//               }
//             >
//               {isUploading ? (
//                 <span className="indicator-progress d-block">
//                   <span className="spinner-border spinner-border-sm align-middle ms-2 w-30px h-30px text-primary"></span>
//                 </span>
//               ) : (
//                 <div className="fw-bold text-muted fs-6 text-center">
//                   Drop Image
//                 </div>
//               )}
//             </div>
//           )}
//           {isEditable && (
//             <a className="upload-pic" onClick={onUploadPic}>
//               <span className="mb-2">
//                 <i className="fa-solid fa-camera fs-2"></i>
//               </span>
//               <span className="d-block fs-8">
//                 Drop file or click to upload image
//               </span>
//             </a>
//           )}
//         </div>
//       )}
//     </>
//   );
// }

// UserSymbol.defaultProps = {
//   size: 50,
//   circle: true,
//   fs: 5,
//   fw: "bold",
//   showOnline: false,
//   noWrapper: false,
//   imageSize: "md",
// };

// const mapStateToProps = ({ user }) => ({
//   team: user.teamMembers,
//   profile: user.profile,
// });

// export default connect(mapStateToProps, null)(UserSymbol);
