import React, { useContext, useState } from "react";
// import { Button, Card, CardBody } from "reactstrap";
// import emitter from "../../helpers/emitter";
// import CraftNotice from "../widgets/Notice";
// import Textarea from "./Textarea";
import auto_suggestions from "../../../../data/auto_suggestions";
import Lottie from "lottie-react-web";
import greetingsAnimationLight from "../../../../assets/animations/greetings_light.json";
import greetingsAnimationDark from "../../../../assets/animations/greetings_dark.json";
// import ImageGallery, { ImageGalleryContext } from "../widgets/ImageGallery";
import { useRef } from "react";
// import PaxfulProfilePic from "../widgets/PaxfulProfilePic";
import platforms from "../../../../data/platforms";
// import PlatformLogo from "../widgets/PlatformLogo";
// import { TextareaMessagesSkeleton } from "../skeleton/AutomationsSkeleton";
import Platform from "components/shared/Platform";
import CraftNotice from "components/CraftNotice";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { Box, Button, Card, Grid } from "@mui/material";
import ATCraftNotice from "components/ATCraftNotice";
import FlexBox from "components/flexbox/FlexBox";
import { Paragraph } from "components/Typography";
import { UserContext } from "page-sections/user-list/ViewUser";
import Textarea from "./Textarea";
import emitter from "helpers/emitter";
import { SettingsContext } from "contexts/settingsContext";
import PlatformLogo from "components/shared/PlatformLogo";
// import Textarea from "./Textarea";

function Greetings({ messages, onChange, activeAccount }) {
  const { userdata, offers } = useContext(UserContext);

  const { settings, saveSettings } = useContext(SettingsContext);

  const _message = {
    enabled: true,
    message: "",
    offers: [],
    file: null,
  };

  const [message, setMessage] = useState(_message);
  const [edited, setEdited] = useState(false);

  const addItem = () => {
    let _messages = [...messages];
    _messages.push(message);

    onChange(_messages);

    //clear inputs

    emitter.emit("clear:select:offers");
    setMessage(_message);
  };

  const removeItem = (index) => {
    let _messages = [...messages];
    _messages.splice(index, 1);

    onChange(_messages);
  };

  const handleTextareaChange = (index, value) => {
    let _messages = [...messages];
    _messages[index] = { ...value };

    if (value.message != "") onChange(_messages);
  };

  //already selected offers
  const selectedOffers = messages?.flatMap((message) => {
    return message.offers.map((offer) => {
      return offer.value;
    });
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const ordered = reorder(
      messages,
      result.source.index,
      result.destination.index
    );

    onChange([...ordered]);
  };

  return (
    <>
      <Box py={2} px={4}>
        <ATCraftNotice
          icon={
            <svg
              width="38"
              height="38"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                fill="currentColor"
              />
              <path
                d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                fill="currentColor"
              />
            </svg>
          }
          type={"primary"}
          title={
            <FlexBox
              color="black"
              sx={{ alignItem: "center", flex: "wrap" }}
              className="d-flex align-items-center flex-wrap"
            >
              <FlexBox
                color="black"
                sx={{ alignItem: "center" }}
                className="d-flex align-items-baseline"
              >
                {"Set Automated Greeting Messages for"}
              </FlexBox>

              <FlexBox
                sx={{ alignItem: "center" }}
                className="user-inner-wrap mt-1 mb-1"
              >
                <Box
                  mx={0.5}
                  sx={{ alignItem: "center", position: "relative" }}
                >
                  <PaxfulProfilePic
                    account={activeAccount}
                    size={20}
                    className={"ms-2"}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: -4,
                      left: 10,
                    }}
                  >
                    <Platform
                      platform={activeAccount?.platform}
                      size={15}
                      className={"w-20px me-1"}
                    />
                  </Box>
                </Box>

                <span className="ms-2">{activeAccount?.paxful_username}</span>
              </FlexBox>
            </FlexBox>
          }
          content={
            <Paragraph color="black" fontSize={13}>
              Auto Greeting will automatically send greeting messages to your
              trade partners when {""}
              {/* <Box sx={{ display: "inline-block" }}> */}
              <PlatformLogo platform={activeAccount?.platform} size={20} />
              {/* </Box> */}
              trades are initiated on the Atlantis platform. This allows you to
              greet your customers before even entering the trade, which also
              improves average trade speed. Simply type your trade
              instructions/greeting message and select which offers it should
              apply to. Images & PDFs also supported.
            </Paragraph>
          }
        />
        <Box
          py={2}
          sx={{ borderBottom: "1px dashed lightgrey" }}
          className="separator separator-dashed my-5 my-xl-10"
        ></Box>
        <Grid container spacing={3} className="mb-1">
          <Grid
            item
            lg={2}
            xl={2}
            sx={{
              display: { xs: "none", md: "none", lg: "block", xl: "block" },
            }}
          >
            {/* Your Lottie animation code */}
            <Box sx={{ transform: " translate3d(0px, 62px, 0px)" }}>
              {settings && settings.theme == "light" && (
                <Lottie
                  options={{
                    animationData: greetingsAnimationLight,
                  }}

                  // width="100%"
                />
              )}
              {settings && settings.theme == "dark" && (
                <Lottie
                  options={{
                    animationData: greetingsAnimationDark,
                  }}

                  // width="100%"
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={5} xl={5} className="mid">
            <Grid container direction="column" mt={3}>
              <Box
                p={2}
                sx={{
                  // border: "1px solid lightgrey",
                  boxShadow: "0px 8px 24px 0px rgba(140, 149, 159, 0.2)",
                }}
                borderRadius={"5px"}
                className="card-shadow overflow--hidden"
              >
                <Box style={{ pointerEvents: "none" }}>
                  {activeAccount && (
                    <Textarea
                      messageSuggestions={auto_suggestions.greetings}
                      selectedOffers={selectedOffers}
                      {...message}
                      onChange={(value) => {
                        setMessage(value);
                      }}
                      uploadImage={true}
                      id="new"
                      buttonLabel={true}
                      valid={true}
                      activeAccount={activeAccount}
                    />
                  )}
                  <div className="d-flex justify-content-start mt-3">
                    <div className="col-md-9 d-flex justify-content-start mt-3">
                      <Button
                        variant="contained"
                        color="success"
                        type="button"
                        disabled={
                          message.message === "" || message.offers.length === 0
                        }
                        className="cursor-pointer btn btn-success btn-sm fs-6 fw-bolder"
                        onClick={() => addItem("field")}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={5} xl={5} className="right">
            <Grid container direction="column" mt-lg={0}>
              {messages &&
                messages.map((message, index) => {
                  return (
                    <Box
                      style={{ pointerEvents: "none" }}
                      mt={3}
                      p={2}
                      sx={{
                        // border: "1px solid lightgrey",
                        boxShadow: "0px 8px 24px 0px rgba(140, 149, 159, 0.2)",
                      }}
                      borderRadius={"5px"}
                      className="card-shadow overflow--hidden"
                    >
                      <Box>
                        <Textarea
                          messageSuggestions={auto_suggestions.greetings}
                          isEditing={true}
                          label={"Greeting"}
                          onChange={(newValue, messageChanged) => {
                            let doUpdate = true;
                            if (
                              messageChanged &&
                              newValue.message == message.message
                            ) {
                              doUpdate = false;
                            }
                            if (doUpdate) handleTextareaChange(index, newValue);
                          }}
                          {...message}
                          onRemove={() => removeItem(index)}
                          uploadImage={true}
                          id={index}
                          valid={true}
                          platform={activeAccount?.platform}
                        />
                      </Box>
                    </Box>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Greetings;
