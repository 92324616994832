import axios from "../utils/axios";
const OfferService = {};

OfferService.getOffers = function (user_id, account_id, type, search) {
  return axios({
    url: `/offers/?user_id=${user_id}&account_id=${account_id}&type=${type}&search=${search}`,
    method: "GET",
  });
};

OfferService.getOfferToggle = function (id, data) {
  return axios({
    url: `/offers/${id}`,
    method: "PUT",
    data,
  });
};
export default OfferService;
