import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { H5, H6 } from "components/Typography";
import React from "react";

function DeleteDialog({ open, title, subTitle, handleCancel, handleConfirm }) {
  return (
    <Dialog
      open={open}
      //  onClose={handleClose}
    >
      <DialogTitle
        style={{
          fontSize: "0.925rem",
        }}
      >
        <H5>{title}</H5>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            fontSize: "0.925rem",
          }}
        >
          <H6> {subTitle}</H6>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleConfirm}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
