import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment-timezone";
import moment from "moment";

import { DATE_FORMAT, DATE_TIME_FORMAT } from "config";

export function FormattedDate({ date }) {
  return <Moment format={DATE_FORMAT}>{date}</Moment>;
}

export function FromNow({ date }) {
  const [now, setNow] = useState(moment());
  const [time, setTime] = useState(now.diff(date, "secs"));

  useEffect(() => {
    const timer = setInterval(() => {
      setNow(moment());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setTime(now.diff(date, "secs"));
  }, [now]);

  let hours = time / (1000 * 60 * 60);
  return hours < 24 ? msToTime(time) : moment(date).fromNow();
}

export function msToTime(milliseconds, ago = true) {
  //Get hours from milliseconds
  var hours = milliseconds / (1000 * 60 * 60);
  var absoluteHours = Math.floor(hours);
  var h = absoluteHours;

  //Get remainder from hours and convert to minutes
  var minutes = (hours - absoluteHours) * 60;
  var absoluteMinutes = Math.floor(minutes);
  var m = absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  var seconds = (minutes - absoluteMinutes) * 60;
  var absoluteSeconds = Math.floor(seconds);
  var s = absoluteSeconds;

  var time = "";

  if (h > 0) {
    time += `${h}h `;
  }

  if (m > 0) {
    time += `${m}m `;
  }

  if (ago) {
    if (h == 0 && m == 0) {
      time += `a few seconds `;
    }
    time += "ago";
  } else {
    //time += `${m}s `;
  }

  return time;
}

// export function FromNow({ date }) {
//   const [now, setNow] = useState(moment());
//   const [time, setTime] = useState(now.diff(date, "secs"));

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setNow(moment());
//     }, 1000);

//     return () => {
//       clearInterval(timer);
//     };
//   }, []);

//   useEffect(() => {
//     setTime(now.diff(date, "secs"));
//   }, [now]);

//   var hours = time / (1000 * 60 * 60);
//   console.log("hours", hours);
//   return hours < 24 ? msToTime(time) : moment(date).fromNow();
// }

export function FormattedDateTime({ date }) {
  return <Moment format={DATE_TIME_FORMAT}>{date}</Moment>;
}

// export function msToTime(milliseconds, ago = true) {
//   //Get hours from milliseconds
//   var hours = milliseconds / (1000 * 60 * 60);
//   var absoluteHours = Math.floor(hours);
//   var h = absoluteHours;

//   //Get remainder from hours and convert to minutes
//   var minutes = (hours - absoluteHours) * 60;
//   var absoluteMinutes = Math.floor(minutes);
//   var m = absoluteMinutes;

//   //Get remainder from minutes and convert to seconds
//   var seconds = (minutes - absoluteMinutes) * 60;
//   var absoluteSeconds = Math.floor(seconds);
//   var s = absoluteSeconds;

//   var time = "";

//   if (h > 0) {
//     time += `${h}h `;
//   }

//   if (m > 0) {
//     time += `${m}m `;
//   }

//   if (ago) {
//     if (h == 0 && m == 0) {
//       time += `a few seconds `;
//     }
//     time += "ago";
//   } else {
//     //time += `${m}s `;
//   }

//   return time;
// }

// export function FromNow({ date }) {
//   const [now, setNow] = useState(moment());

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setNow(moment());
//     }, 1000);

//     return () => {
//       clearInterval(timer);
//     };
//   }, []);

//   const timeDiff = moment.duration(now.diff(date));
//   const hours = timeDiff.hours();
//   const minutes = timeDiff.minutes();
//   const seconds = timeDiff.seconds();

//   if (hours < 24) {
//     return msToTime(hours, minutes, seconds);
//   } else {
//     return moment(date).fromNow();
//   }
// }

// function msToTime(hours, minutes, seconds) {
//   let time = "";

//   if (hours > 0) {
//     time += `${hours}h `;
//   }

//   if (minutes > 0) {
//     time += `${minutes}m `;
//   }

//   if (hours === 0 && minutes === 0 && seconds === 0) {
//     time += "a few seconds";
//   }

//   if (time === "") {
//     time = "less than a minute";
//   }

//   return `${time} ago`;
// }
