import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, ContentCopy } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import FlexBox from "components/flexbox/FlexBox";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import PaxfulAccount from "components/shared/PaxfulAccount";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PaxfulAccountForJSONData from "./profileWithAccount";
import notification from "helpers/notifications";
import ATooltip from "components/shared/ATooltip";
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};
const PaxfulApiColumnShape = [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>DATE</Box>,
    accessor: "createdAt",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        <FormattedDateTime date={value} />
      </Paragraph>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>END POINT</Box>,
    accessor: "endpoint",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "12px" }}>VENDOR</Box>,
    accessor: "user",
    Cell: ({ value }) => (
      <Stack direction="row" spacing={0} alignItems={"center"}>
        {value && <UserSymbol user={value} size={25} />}

        <Box
          sx={{
            marginLeft: "10px !important",
          }}
        >
          <Paragraph fontSize={13} color="text.secondary">
            {value == null ? "-" : value?.first_name}
          </Paragraph>
        </Box>
      </Stack>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>ACCOUNT</Box>,
    accessor: "account",
    Cell: ({ value }) => (
      <>
        <FlexBox justifyContent="start" alignItems="center">
          {value && <PaxfulAccountForJSONData item={value} size={25} />}
        </FlexBox>
      </>
    ),
  },
  {
    Header: "STATUS",
    accessor: "status",
    Cell: ({ value }) => (
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".2rem 1rem",
          backgroundColor: value === "success" ? "success.main" : "error.main",
        }}
      >
        {value == "success" ? "success" : "Fail"}
      </Small>
    ),
  },
];
export default PaxfulApiColumnShape;
