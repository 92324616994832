import notification from "helpers/notifications";
import parsePhoneNumber from "libphonenumber-js";

export const searchByName = (listData, searchValue) => {
  if (searchValue.length > 0) {
    const searchResult = listData.filter((item) =>
      item.name.toLocaleLowerCase().match(searchValue.toLowerCase())
    );
    return searchResult;
  } else {
    return listData;
  }
};
export const searchByField = (listData, searchValue, field) => {
  if (searchValue.length > 0) {
    const searchResult = listData.filter((item) =>
      item[field].toLocaleLowerCase().match(searchValue.toLowerCase())
    );
    return searchResult;
  } else {
    return listData;
  }
};
export const getRoute = (pathname) => {
  const str = pathname.split("/");
  return `/${str[1]}`;
};
// export const validateEmail = (email) => {
//   return String(email)
//     .toLowerCase()
//     .match(
//       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//     );
// };
export const getRoleColor = (role) => {
  switch (role) {
    case "admin":
      return "info.main";
    case "manager":
      return "primary.main";
    case "va":
      return "primary.main";
    case "employee":
      return "primary.main";
  }
};

export const getRoleName = (role) => {
  switch (role) {
    case "admin":
      return "Vendor";
    case "manager":
      return "Admin";
    case "employee":
      return "Employee";
    case "va":
      return "Assistant";
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case "new":
      return "info.main";
    case "cancelled":
      return "error.main";
    case "paid":
      return "primary.main";
    case "released":
      return "success.main";
    case "disputed":
      return "warning.main";
  }
};
export const getStatusTag = (status, trade_status) => {
  switch (status) {
    case "new":
      return trade_status;
    case "cancelled":
      return trade_status;
    case "paid":
      return trade_status;
    case "released":
      return "Successful";
    case "disputed":
      return trade_status;
  }
};

export const getUserReqStatusColor = (status) => {
  switch (status) {
    case "closed":
      return "error.main";
    case "pending":
      return "primary.main";
    case "completed":
      return "success.main";
    case "processing":
      return "primary.main";
  }
};

export const getUserReqStatus = (value) => {
  switch (value) {
    case "closed":
      return "Closed";
    case "pending":
      return "Pending";
    case "completed":
      return "Completed";
    case "processing":
      return "Processing";
  }
};

export const getOfferTag = (tag) => {
  switch (tag) {
    case "sell":
      return "Sell";
    case "buy":
      return "Buy";
  }
};
export const getOfferColor = (offer) => {
  switch (offer) {
    case "sell":
      return "error.main";
    case "buy":
      return "success.main";
  }
};

export const arrayIsEmpty = (array) => {
  if (array.length == 0) {
    return true;
  } else {
    return false;
  }
};

export const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};

export const copyToClipboard = (target_id) => {
  const coppied = document.querySelector(".copy-link.coppied");
  if (coppied) {
    coppied.classList.remove("coppied");
  }

  var copyText = document.getElementById(target_id);
  copyText.select();
  copyText.setSelectionRange(0, 99999);

  const trigger = document.querySelector(`.copy-link.${target_id}-trigger`);
  if (trigger) {
    trigger.classList.add("coppied");
  }
  copyTextToClipboard(copyText.value);
};

export function truncateText(text, start, end) {
  if (start >= end) {
    return text; // Invalid range, return the original text
  }

  // Extract the desired portion of the text
  var truncatedText = text.substring(0, start) + "..." + text.substring(end);

  return truncatedText;
}

export function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getFileExtension(url) {
  return url.split("/").pop().split(".").pop();
}

export function getTitleFromSlug(slug) {
  var title = slug.split("-");
  return title
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");
}

export function capitalizeFLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatMobileNumber(phnNum) {
  const phoneNumber = parsePhoneNumber(phnNum);
  return phoneNumber.formatInternational();
}
