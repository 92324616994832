import React from "react";
import { getStatusColor } from "utils/utils";
import { Small } from "components/Typography";
import { getStatusTag } from "utils/utils";

function TradeStatus({ _trade }) {
  return (
    <Small
      sx={{
        width: 100,
        fontSize: 10,
        color: "#fff",
        borderRadius: "4px",
        textAlign: "center",
        padding: ".2rem",
        backgroundColor: getStatusColor(_trade?.status),
      }}
    >
      {getStatusTag(_trade?.status, _trade?.trade?.trade_status)}
    </Small>
  );
}

export default TradeStatus;
