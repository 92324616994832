import LoadingScreen from "components/LoadingScreen";
import DashboardLayoutV3 from "layouts/layout-v3/DashboardLayout";
import Users from "pages/users";
import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import ViewUser from "page-sections/user-list/ViewUser";
import View from "page-sections/profiles/profile/Trades/View";

import Profile from "page-sections/user-list/Profile";
import UserProfile from "page-sections/user-list/Profile";
import Management from "pages/Management";
import ActivityLog from "pages/ActivityLog";
import PaxfulApilog from "pages/PaxfulApilog";
import VirtualAssistance from "pages/VirtualAssistance";
import ViewVa from "page-sections/user-list/ViewVa";
import UserRequest from "pages/UserRequest";
import VirtualAsssistant from "pages/VirtualAsssistant";
import HireRequest from "pages/HiringRequest";
import TradeHistory from "pages/TradeHistory";
import HireViewRequest from "page-sections/hiring-request/HireViewRequest";
import Setting from "pages/Setting";
import ViewTransaction from "page-sections/profiles/profile/blockchainTransaction/ViewTransaction";
import Buyers from "pages/Buyers";
import ViewBuyer from "page-sections/user-list/ViewBuyer";
import PendingInvitations from "pages/PendingInvitations";
import ViewUserRequest from "page-sections/user-request/ViewUserRequest";
import Prepaid2Coin from "pages/Prepaid2Coin";
import ViewP2P from "page-sections/prepaid2Coin/ViewP2P";
import UserMasterList from "pages/UserMasterList";
import ActiveTrades from "pages/ActiveTrades";
import AtlantisRevenue from "pages/AtlantisRevenue";
import VendorAccount from "pages/VendorAccount";
import HireVirtualAssistant from "pages/HireVirtualAssistant";
import RealVA from "pages/RealVA";
import VirtualAccount from "pages/VirtualAccount";
import WebhookLog from "pages/WebhookLog";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

//login
const Login = Loadable(lazy(() => import("./pages/login")));

//dashboard
const SaaS = Loadable(lazy(() => import("./pages/dashboard")));
const Webhooks = Loadable(lazy(() => import("./pages/webhooks")));

const Error = Loadable(lazy(() => import("./pages/404")));

const routes = () => {
  return [
    ...authRoutes,
    {
      path: "dashboard",
      element: <DashboardLayoutV3 />,
      children: dashboardRoutes,
    },
    {
      path: "*",
      element: <Error />,
    },
  ];
};

const authRoutes = [
  {
    path: "/",
    element: <Login />,
  },
];

const dashboardRoutes = [
  {
    path: "",
    element: <SaaS />,
  },
  {
    path: "webhooks",
    element: <Webhooks />,
  },
  {
    path: "users",
    element: <Users />,
  },
  {
    path: "users/:id",
    element: <ViewUser />,
  },
  {
    path: "users/:id/trade",
    element: <View />,
  },
  // {
  //   path: "virtualassistence/profile",
  //   element: <UserProfile />,
  // },
  {
    path: "va-agencies",
    element: <VirtualAssistance />,
    // element: <VirtualAssistence />,
  },
  {
    path: "va-agencies/:id",
    element: <ViewVa />,
  },
  {
    path: "management",
    element: <Management />,
  },
  {
    path: "activity-log",
    element: <ActivityLog />,
  },
  {
    path: "api-log",
    element: <PaxfulApilog />,
  },
  {
    path: "webhook-log",
    element: <WebhookLog />,
  },
  {
    path: "user-request",
    element: <UserRequest />,
  },
  {
    path: "virtual-assistant",
    element: <VirtualAsssistant />,
  },
  {
    path: "hire-requests",
    element: <HireRequest />,
  },
  {
    path: "hire-requests/:id",
    element: <HireViewRequest />,
  },
  {
    path: "trade-history",
    element: <TradeHistory />,
  },
  {
    path: "setting",
    element: <Setting />,
  },
  {
    path: "users/:user_id/transactions/:id",
    element: <ViewTransaction />,
  },
  {
    path: "buyers",
    element: <Buyers />,
  },
  {
    path: "buyers/:id",
    element: <ViewBuyer />,
  },
  {
    path: "pending-invitations",
    element: <PendingInvitations />,
  },
  {
    path: "user-request/:id",
    element: <ViewUserRequest />,
  },
  {
    path: "prepaid2Coin",
    element: <Prepaid2Coin />,
  },
  {
    path: "prepaid2Coin/:id",
    element: <ViewP2P />,
  },
  {
    path: "user-master-list",
    element: <UserMasterList />,
  },
  {
    path: "real-va",
    element: <RealVA />,
  },
  {
    path: "active-trade",
    element: <ActiveTrades />,
  },
  {
    path: "atlantis-revenue",
    element: <AtlantisRevenue />,
  },
  {
    path: "vendor-accounts",
    element: <VendorAccount />,
  },
  {
    path: "hire-virtual-assistant",
    element: <HireVirtualAssistant />,
  },
  {
    path: "virtual-accounts",
    element: <VirtualAccount />,
  },
];

export default routes;
