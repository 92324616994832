import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import FlexBox from "components/flexbox/FlexBox";
import { COMMON_API_BASE_URL } from "..//../config";
import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import HiringRequestService from "services/HireRequestService";
import { FormattedDateTime } from "components/shared/FormattedDate";
import { H6, Paragraph, Small, Span } from "components/Typography";
import { formatCurrency } from "utils/currencies";
import { FormatBold } from "@mui/icons-material";
import { StatusBadge, VASchedule } from "./ColumnShape";
import ScheduleViewer from "components/schedule-viewer/ScheduleViewer";
import CraftNotice from "components/CraftNotice";
import { styled } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CountryFlag from "components/shared/CountryFlag";
import CopyToClipboard from "react-copy-to-clipboard";
import Copy from "icons/Copy";
import Platform from "components/shared/Platform";
import Timezone from "components/shared/Timezone";
import { copyTextToClipboard } from "utils/utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { SettingsContext } from "contexts/settingsContext";
import UserProfile from "components/avatars/UserProfile";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";

const CryptoIcon = styled("img")`
  height: "120px !important",
  width: "120px" !important,
  position: "absolute",
  top: "10%",
  left: "10%",
`;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "120px",
    width: "120px",
    backgroundColor: "aliceblue", // Replace with the path to your background image
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderRadius: "8px",

    // Add any other styles you want for the container
  },
}));

// const VendorStats = styled(Box)`
// border: "1px dashed lightgrey",
// borderRadius: "10px",
// color : "red",
// minWidth: "115px",
// `;

const VendorStats = styled(Box)(({ theme }) => ({
  border: "1px dashed lightgrey",
  borderRadius: "10px",
}));

function HireViewRequest() {
  const { id } = useParams();

  const { settings } = useContext(SettingsContext);
  console.log("settings", settings);

  const [request, setRequest] = useState(null);
  const [stats, setstats] = useState(null);
  const [vaSchedule, setVaSchedule] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const func = async () => {
    setisLoading(true);

    let resp = await HiringRequestService.singleHireRequest(id);
    setRequest(resp.records);
    setstats(resp.stats);
    setisLoading(false);
  };

  useEffect(() => {
    func();
  }, []);

  const classes = useStyles();

  const iconColor = settings.theme === "dark" ? "gray" : "skyblue"; // Adjust color based on theme
  const textColor = settings.theme === "dark" ? "white" : "black";

  console.log("request.vaSchedule", request);
  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2} columns={16} mt={2}>
          <Grid xs={7}>
            <Card
              sx={{
                minHeight: "660px !important",
              }}
            >
              {/* {!request && <HireViewRequestProfileSkelton />} */}

              <CardContent>
                {request &&
                  (console.log("REQUEST", request),
                  (
                    <>
                      <FlexBox>
                        <Box>
                          {/* img */}
                          {request?.assignedVA?.profile_pic?.file_path ? (
                            <Box
                              className={classes.container}
                              sx={{
                                boxSizing: "border-box",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                src={`${COMMON_API_BASE_URL}/${request?.assignedVA?.profile_pic?.file_path}`}
                                alt="image"
                              />
                            </Box>
                          ) : (
                            <>
                              <FlexBox
                                className={classes.container}
                                justifyContent="center"
                                alignItems="center"
                                backgroundColor="success.main"
                              >
                                <Small color="white" fontSize={50}>
                                  {request?.assignedVA?.first_name
                                    ? request?.assignedVA?.first_name
                                        ?.substr(0, 1)
                                        .toUpperCase()
                                    : request?.assignedVA?.email
                                        ?.substr(0, 1)
                                        .toUpperCase()}
                                </Small>
                              </FlexBox>
                            </>
                          )}
                        </Box>
                        <Box ml={2}>
                          {/* info */}
                          <FlexBox
                            sx={{
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                            }}
                          >
                            <Box mr={2}>
                              <FlexBox
                                sx={{ alignItems: "center" }}
                                className="d-flex align-items-center"
                              >
                                {/* <span className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"></span> */}
                                <Paragraph
                                  fontSize={18}
                                  ml={0.6}
                                  marginRight={1}
                                >
                                  {" "}
                                  {request.assignedVA.name}
                                </Paragraph>
                                <CountryFlag
                                  code={request.assignedVA?.country}
                                  width={"20px"}
                                />
                                <Small ml={0.8}>
                                  <Timezone
                                    timezone={request.assignedVA.timezone}
                                    color={"light"}
                                    className={"text-gray-800"}
                                  />
                                </Small>
                              </FlexBox>

                              <FlexBox className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">
                                <Paragraph
                                  fontSize={13}
                                  color="text.secondary"
                                  ml={0.6}
                                >
                                  {request.assignedVA.experience} of Noones
                                  experience
                                </Paragraph>
                              </FlexBox>
                              <FlexBox
                                sx={{ alignItems: "center" }}
                                className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400"
                              >
                                <Paragraph
                                  ml={0.6}
                                  fontSize={13}
                                  color="text.secondary"
                                >
                                  Only has access to trading tool
                                </Paragraph>
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x="2"
                                    y="2"
                                    width="20"
                                    height="20"
                                    rx="5"
                                    fill="green"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="green"
                                  />
                                </svg>
                              </FlexBox>
                            </Box>
                            <Box>
                              {/* Show badge */}
                              <Box>
                                {request.vaSchedule && request.vaSchedule ? (
                                  <VASchedule schedule={request.vaSchedule} />
                                ) : (
                                  <StatusBadge
                                    color={"success.main"}
                                    title={"Completed"}
                                  />
                                )}
                              </Box>
                            </Box>
                          </FlexBox>
                          <FlexBox
                            sx={{
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                            }}
                            mt={1}
                          >
                            <Box
                              className="fee-box"
                              style={{
                                border: "1px dashed lightgrey",
                                borderRadius: "10px",
                              }}
                              p={1}
                              m={0.5}
                              // ml={0}
                            >
                              <Paragraph fontSize={11} color="text.secondary">
                                Fee
                              </Paragraph>

                              <Box className="fs-3 fw-bolder">
                                <Paragraph fontSize={18} fontWeight="bold">
                                  {formatCurrency(request.fee)}
                                </Paragraph>
                              </Box>
                              <Paragraph fontSize={12} color="text.secondary">
                                {request.hours}{" "}
                                {request.hours > 1 ? "hours" : "hour"} @{" "}
                                {formatCurrency(request.rate)} / hr
                              </Paragraph>
                            </Box>
                            {request?.assignedUser && (
                              <Box
                                className="assigned-va-box"
                                // ml={1}
                                // mt={1}
                                style={{
                                  border: "1px dashed lightgrey",
                                  borderRadius: "10px",
                                }}
                                p={1}
                                m={0.5}
                              >
                                <Paragraph fontSize={11} color="text.secondary">
                                  Assigned VA
                                </Paragraph>

                                <UserProfile user={request?.assignedUser} />
                              </Box>
                            )}
                          </FlexBox>
                        </Box>
                      </FlexBox>

                      {stats && (
                        <Box mt={0.5}>
                          <H6 mb={1}>Stats</H6>
                          <FlexBox mt={1}>
                            <VendorStats p={1}>
                              <Paragraph fontSize={11} color="text.secondary">
                                Total Trades
                              </Paragraph>

                              <Box className="fs-3 fw-bolder">
                                <Paragraph fontSize={18} fontWeight="bold">
                                  {stats.released + stats.cancelled}
                                </Paragraph>
                              </Box>
                            </VendorStats>
                            <VendorStats ml={2} p={1}>
                              <Paragraph fontSize={11} color="text.secondary">
                                Completed Trades
                              </Paragraph>

                              <Box className="fs-3 fw-bolder">
                                <Paragraph fontSize={18} fontWeight="bold">
                                  {stats.released}
                                </Paragraph>
                              </Box>
                            </VendorStats>
                            <VendorStats ml={2} p={1}>
                              <Paragraph fontSize={11} color="text.secondary">
                                Cancelled Trades
                              </Paragraph>

                              <Box className="fs-3 fw-bolder">
                                <Paragraph fontSize={18} fontWeight="bold">
                                  {stats.cancelled}
                                </Paragraph>
                              </Box>
                            </VendorStats>
                          </FlexBox>
                        </Box>
                      )}
                      <Box mt={2}>
                        <H6 mb={1}>Security Pin</H6>
                        {request?.securityPin ? (
                          <StatusBadge
                            color={"success.main"}
                            title={"Enable"}
                          />
                        ) : (
                          <StatusBadge color={"error.main"} title={"Disable"} />
                        )}
                      </Box>

                      <Box my={2}>
                        <H6 mb={1}>Schedule</H6>
                        <ScheduleViewer
                          date={request.createdAt}
                          schedule={request.schedule}
                          timezone={request.user.timezone}

                          // timeZoneAbbr={timeZoneAbbr}
                        />
                      </Box>
                      {
                        <CraftNotice
                          icon={
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.3"
                                d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                                fill={iconColor} // Set the fill color based on the theme
                              />
                              <path
                                d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                                fill={iconColor} // Set the fill color based on the theme
                              />
                            </svg>
                          }
                          content={
                            <>
                              <Paragraph
                                sx={{
                                  color: "black",
                                }}
                              >
                                Please note that schedule is set to vendor
                                timezone, which is{" "}
                                <strong>{request.user.timezone}</strong>{" "}
                                Timezone: {/* <{"Vendor Timezone"} /> */}
                              </Paragraph>
                            </>
                          }
                        />
                      }
                    </>
                  ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={8} ml={1}>
            <Card
              sx={{
                height: "660px !important",
                overflowY: "hidden", // Hide scrollbar in Card
              }}
            >
              <CardContent>
                <div className="mb-3">
                  <h3>Offers</h3>
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #8CA3BA",
                        }}
                      >
                        <Paragraph fontSize={11} color="text.secondary">
                          CRYPTO
                        </Paragraph>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #8CA3BA",
                        }}
                      >
                        {" "}
                        <Paragraph fontSize={11} color="text.secondary" mr={20}>
                          PAYMENT METHOD
                        </Paragraph>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #8CA3BA",
                        }}
                      >
                        {" "}
                        <Paragraph fontSize={11} color="text.secondary" mr={6}>
                          RANGE
                        </Paragraph>
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "1px solid #8CA3BA",
                        }}
                      >
                        <Paragraph fontSize={11} color="text.secondary">
                          MARGIN
                        </Paragraph>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <Box mt={1}>
                  <TableContainer
                    sx={{
                      maxHeight: 500,
                    }}
                  >
                    <Table>
                      {/* </Table>
                    <Divider />
                    <Box
                      sx={{
                        height: "500px",
                        overflowY: "auto",
                      }}
                    > */}
                      {/* <Table> */}
                      <TableBody>
                        {request?.offers &&
                          request.offers.map(({ account, offer, index }) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell>
                                    <CryptoIcon
                                      style={{
                                        height: "30px",
                                      }}
                                      src={`/media/svg/coins/${offer.crypto_currency_code.toLowerCase()}.svg`}
                                      alt="image"
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FlexBox sx={{ alignItems: "center" }}>
                                      <Box mr={0.5}>
                                        <PaxfulProfilePic
                                          size={20}
                                          account={account}
                                        />
                                      </Box>
                                      <Paragraph
                                        fontSize={15}
                                        color={
                                          offer.active
                                            ? textColor
                                            : "text.secondary"
                                        }
                                        fontWeight="bold"
                                      >
                                        {offer.payment_method_name}
                                      </Paragraph>
                                      <Box ml={0.5}>
                                        <Platform
                                          size={20}
                                          platform={account?.platform}
                                        />
                                      </Box>
                                    </FlexBox>
                                    <FlexBox alignItems="center">
                                      <Small
                                        sx={{
                                          width: 40,
                                          fontSize: 10,
                                          color: "#fff",
                                          borderRadius: "4px",
                                          textAlign: "center",
                                          backgroundColor:
                                            offer.offer_type == "buy"
                                              ? "success.main"
                                              : "error.main",
                                        }}
                                      >
                                        {offer.offer_type}
                                      </Small>
                                      <a
                                        style={{
                                          fontSize: "10px",
                                          marginLeft: "5px",
                                        }}
                                        href={`https://${account?.platform}.com/offer/${offer.offer_hash}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-muted fs-7 mt-0 text-hover-primary"
                                      >
                                        {offer.offer_hash}
                                      </a>
                                      <Box ml={1}>
                                        <ContentCopyIcon
                                          sx={{
                                            fontSize: 15,
                                            cursor: "pointer",
                                            color: "primary.main",
                                            marginTop: 0.5,
                                          }}
                                          onClick={() =>
                                            copyTextToClipboard(
                                              offer.offer_hash
                                            )
                                          }
                                        />
                                      </Box>
                                    </FlexBox>
                                  </TableCell>
                                  <TableCell>
                                    <Paragraph
                                      fontSize={10}
                                      color="text.secondary"
                                    >
                                      {formatCurrency(
                                        offer.fiat_amount_range_min,
                                        offer.fiat_currency_code
                                      )}{" "}
                                      -{" "}
                                      {formatCurrency(
                                        offer.fiat_amount_range_max,
                                        offer.fiat_currency_code
                                      )}
                                    </Paragraph>
                                  </TableCell>
                                  <TableCell>
                                    <Paragraph
                                      fontSize={13}
                                      color="text.secondary"
                                    >
                                      {`${offer.margin}%`}
                                    </Paragraph>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                      </TableBody>
                    </Table>
                    {/* </Box> */}
                  </TableContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default HireViewRequest;
