import { Edit } from "@mui/icons-material";
import { IconButton, styled, Button, Box, Stack } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import FlexBox from "components/flexbox/FlexBox";
import { Link } from "react-router-dom";
import { Tiny } from "components/Typography";
import { ArrowOutward, ArrowRight } from "@mui/icons-material";

import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import TradeStatus from "components/shared/TradeStatus";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import BuyerProfilePic from "components/shared/BuyerProfilePic";
import PaxfulAccount from "components/shared/PaxfulAccount";
import UserSymbol from "components/shared/UserSymbol";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));

const P2PColumnShape = [
  {
    Header: "DATE",
    accessor: "startedAt",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        <FormattedDateTime date={value} />
      </Paragraph>
    ),
  },
  {
    Header: "VENDOR",
    accessor: "user",
    Cell: ({ value }) => (
      <Stack direction="row" spacing={2} alignItems={"center"}>
        {value && <UserSymbol user={value} size={25} />}

        <Box
          sx={{
            marginLeft: "10px !important",
          }}
        >
          <Paragraph fontSize={13} color="text.secondary">
            {value == null ? "-" : value.first_name}
          </Paragraph>
        </Box>
      </Stack>
    ),
  },
  {
    Header: "FULL NAME",
    accessor: "row",
    Cell: ({ row }) => {
      const { first_name, last_name } = row.original.user;
      return (
        <Box>
          <Paragraph fontSize={13} color="text.secondary">
            {first_name + " " + last_name}
          </Paragraph>
        </Box>
      );
    },
  },
  {
    Header: "DISCORD",
    accessor: "dsds",
    Cell: ({ row }) => {
      const { discord_username, discord_profile } = row.original.user;
      return (
        <>
          <FlexBox sx={{ alignItems: "center" }} justifyContent="start">
            <FlexBox ml={1}>
              <img
                src={
                  discord_profile
                    ? discord_profile
                    : "/media/logos/discord-logo-png-7617.png"
                }
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                }}
              />
            </FlexBox>
            <Box ml={1}>
              {" "}
              <Span
                sx={{
                  color: discord_username ? "grey.400" : "primary.main",
                  marginRight: "2px",
                }}
              >
                {discord_username ? discord_username : "Assign"}
              </Span>{" "}
            </Box>
          </FlexBox>
        </>
      );
    },
  },

  {
    Header: "STATUS",
    accessor: "status",
    Cell: ({ value }) => {
      return (
        <Small
          sx={{
            width: 100,
            fontSize: 10,
            color: "white",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor: value ? "success.main" : "error.main",
          }}
        >
          {value ? "Approved" : "Pending"}
        </Small>
      );
    },
  },
  {
    Header: "MOBILE",
    accessor: "basic_info",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value.mobile_number}
      </Paragraph>
    ),
  },
  {
    Header: "STATE",
    accessor: "aaaaa",
    Cell: ({ row }) => {
      const { state } = row.original.basic_info;
      return (
        <Paragraph fontSize={13} color="text.secondary">
          {state}
        </Paragraph>
      );
    },
  },
  {
    Header: "ACTIONS",
    accessor: "bbbbb",
    Cell: ({ row }) => {
      const { _id } = row.original;
      return (
        <StyledButton
          component={Link}
          to={`/dashboard/prepaid2Coin/${_id}`}
          variant="outlined"
          color="primary"
          size="small"
        >
          View
        </StyledButton>
      );
    },
  },
  // mobile_number
];
export default P2PColumnShape;
