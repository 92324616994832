import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RouteGuard from "components/RouteGuard";
import RTL from "components/RTL";
import { AppNotification } from "helpers/notifications";
import useSettings from "hooks/useSettings";
import { useRoutes } from "react-router-dom";
import routes from "routes";
import { createCustomTheme } from "theme";
import "./i18n";

import "./styles/custom.scss";
import { createContext, useEffect, useState } from "react";
import ProfileService from "services/ProfileService";
import { Socket } from "socket.io-client";
import socketHelper from "helpers/socketHelper";
import emitter from "helpers/socketEmmiter";
import HiringRequestService from "services/HireRequestService";

export const UserContextData = createContext({
  liveRate: {},
  hireRequestCount: 0,
  userRequestCount: 0,
  userdata: {},
  setUserData: () => {},
  userRequest: null,
  setUserRequest: () => {},
  reload: false,
  setReload: () => {},
  profileReloaded: false,
  setHireRequestCount: () => {},
  hireRequest: null,
  setHireRequest: () => {},
});

const App = () => {
  let socket;
  let listner;

  const [userdata, setUserData] = useState({});
  const [profileReloaded, setProfileReloaded] = useState(false);
  const [userRequest, setUserRequest] = useState(null);
  const [userRequestCount, setUserRequestCount] = useState(0);

  const [reload, setReload] = useState(false);
  const [hireRequestCount, setHireRequestCount] = useState(0);
  const [hireRequest, setHireRequest] = useState(null);
  const [liveRate, setLiveRate] = useState({});

  useEffect(() => {
    socket = socketHelper.connect("atlantis-admin");
    socket.on("disconnect", () => {
      listner?.remove();
    });

    socket.on("connect", () => {
      console.log("socket connected");
      listner = emitter.addListener("socket:emit", function ({ event, data }) {
        console.log("emit: ", socket.id, event);
        socket.emit(event, data);
      });
    });

    socket.on("user:request:updated", (data) => {
      if (userRequest && userRequest._id == data._id) {
        // console.log("userRequest", userRequest);
        setUserRequest(data);
      }
    });
    socket.on("user:request:count", (data) => {
      // console.log("user:profile:reloaded");
      console.log("user:request:count", data);

      setUserRequestCount(data);
    });
    socket.on("user:profile:reloaded", (data) => {
      // console.log("user:profile:reloaded");
      if (data) {
        setProfileReloaded(data);
      }
    });
    socket.on("user:unAssigned:request", (data) => {
      // console.log("user:profile:reloaded");
      console.log("user:unAssigned:request", data);

      setHireRequestCount(data);
    });
    socket.on("va:hire_requests:updated", (data) => {
      console.log("user:profile:reloaded");
      setHireRequest(data);
    });

    socket.on("live:crypto:rates", (rates) => {
      setLiveRate(rates);
    });
    // console.log("userRequest", userRequest);

    return () => {
      socket.removeAllListeners();
      listner?.remove();
    };
  }, [userRequest, profileReloaded]);

  const content = useRoutes(routes());

  const { settings } = useSettings();
  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <UserContextData.Provider
      value={{
        hireRequestCount,
        userRequestCount,
        userdata,
        setUserData,
        userRequest,
        setUserRequest,
        reload,
        setReload,
        profileReloaded,
        setHireRequestCount,
        liveRate,
        hireRequest,
        setHireRequest,
      }}
    >
      <RouteGuard>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <RTL>
              <CssBaseline />
              {content}
              <AppNotification />
            </RTL>
          </ThemeProvider>
        </StyledEngineProvider>
      </RouteGuard>
    </UserContextData.Provider>
  );
};

export default App;
