import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5, Paragraph } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import InvoiceListTable from "page-sections/invoice/list";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components
import List from "./List";

import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import WalletService from "services/WalletService";
import { LoadingButton } from "@mui/lab";
import BlockChainTrasnService from "services/BlockChainTrasnService";
import supportedAssets from "data/supportedAssets";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const BlockchainTransaction = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [filter, setFilter] = useState("");
  const [option, setOption] = useState("");

  // ================================
  // wallet Transactions
  // =================================
  const { userdata, bcTransactions, setBcTrasnactions } =
    useContext(UserContext);

  const [isLoading, setisLoading] = useState(false);
  // const [transactions, setTrasnactions] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    getbcTransactions(userdata._id, 1, limit, option, filter);

    return () => {
      isMounted.current = false;
    };
  }, [option, filter]);

  const getbcTransactions = async (id, page, limit, method, paid_for) => {
    if (isMounted.current) {
      setisLoading(true);
      const bcTransactions = await BlockChainTrasnService.getBcTranscation(
        id,
        page,
        limit,
        method,
        paid_for
      );
      if (isMounted.current) {
        setBcTrasnactions(bcTransactions);
        setPage(page);
        setisLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setOption(value);
    setPage(1);
    getbcTransactions(userdata._id, 1, limit, value, filter);
  };

  const filterChange = (event) => {
    const value = event.target.value;
    setFilter(value);
    setPage(1);
    getbcTransactions(userdata._id, 1, limit, option, value);
  };

  const reload = () => {
    setOption("");
    setFilter("");
    getbcTransactions(userdata._id, 1, limit, "", "");
  };

  const handlePageChange = (page) => {
    setPage(page);
    setLimit(limit);
    getbcTransactions(userdata._id, page, limit, option, filter);
  };

  const handleLimitChange = (event) => {
    setPage(1);
    setLimit(event.target.value);
    getbcTransactions(userdata._id, 1, event.target.value, option, filter);
  };

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Atlantis Transactions")}</H5>
        </Stack>
        <StyledStack alignItems="center" direction="row" gap={2}>
          {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              sx={{
                "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px !important", // Remove padding from the Select component
                  },
              }}
              value={option}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="all">
                <Paragraph>All</Paragraph>
              </MenuItem>
              {supportedAssets.map((item) => {
                return (
                  <MenuItem value={item.assetID}>
                    {" "}
                    <Paragraph>{item.title}</Paragraph>{" "}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              sx={{
                "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px !important",
                  },
              }}
              value={option}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <Paragraph>All</Paragraph>
              </MenuItem>
              <MenuItem value={"lightning"}>
                {" "}
                <Paragraph>Lightning</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"cc"}>
                {" "}
                <Paragraph>Credit Card</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"paxfulpay"}>
                {" "}
                <Paragraph>Paxful Pay</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"crypto"}>
                {" "}
                <Paragraph>Crypto</Paragraph>{" "}
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              sx={{
                "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px !important",
                  },
              }}
              value={filter}
              onChange={filterChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <Paragraph>All</Paragraph>
              </MenuItem>
              <MenuItem value={"va"}>
                {" "}
                <Paragraph>Hire Virtual Assistant</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"trading"}>
                {" "}
                <Paragraph>Trading Volume Topup</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"subscription"}>
                {" "}
                <Paragraph>Atlantis Membership</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"credit"}>
                {" "}
                <Paragraph>Deposit</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"debit"}>
                {" "}
                <Paragraph>Withdrawal</Paragraph>{" "}
              </MenuItem>
            </Select>
          </FormControl>
          <LoadingButton
            onClick={() => reload()}
            variant="contained"
            loading={isLoading}
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        bcTransactions &&
        bcTransactions.data && (
          <List
            {...bcTransactions}
            onPageChange={handlePageChange}
            currentPage={page}
            handleLimitChange={handleLimitChange}
            limit={limit}
          />
        )
      )}
    </Box>
  );
};

export default BlockchainTransaction;
