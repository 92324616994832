import React from 'react'
import { Box, Button, IconButton, Stack, styled } from "@mui/material";
import { H6, Paragraph, Small, Tiny } from "components/Typography";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import { SERVER_BASE_URL } from "config";
import FlexBox from 'components/flexbox/FlexBox';
import UserSymbol from 'components/shared/UserSymbol';
import UserSymbolAT from 'components/shared/UserSymbolAT';

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

const RvaColumnShape = (onCellClick, onEdit, confirmDeleteUser) => [
  {
    Header: "DATE",
    accessor: "createdAt",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        <FormattedDateTime date={value} />
      </Paragraph>
    ),
  },
  {
    Header: "NAME",
    accessor: (row) => `${row.first_name} ${row.last_name} `,
    Cell: ({ row }) => {
      const { first_name, last_name, profile_pic, email } = row.original;
      return (
        <>
       <FlexBox alignItems="center">
          {profile_pic ? (
            <Box mr={1}>
              <img
                style={{
                  width: `30px`,
                  height: `30px`,
                  borderRadius: "50%",
                }}
                src={`${SERVER_BASE_URL}/${profile_pic?.file_path}`}
                alt="User"
              />
            </Box>
          ) : (
            <Box mr={1}>
              <UserSymbol user={row.original} size={30} />
            </Box>
          )}
          <Paragraph fontSize={13} ml={2} color="text.secondary">
            {`${first_name} ${last_name}`} <br/>
            {email}
          </Paragraph>
        </FlexBox>
        </>
      );
    },
  },

  {
    Header: "TIMEZONE",
    accessor: "timezone",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "COUNTRY",
    accessor: "country",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "ACTIONS",
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <>
          <StyledButton
            sx={{
              ml: 2,
            }}
            onClick={() => onEdit(row.original)}
            variant="outlined"
            size="small"
          >
            Edit
          </StyledButton>
          
          <StyledButton
            sx={{
              ml: 2,
            }}
            onClick={() => confirmDeleteUser(row.original)}
            variant="outlined"
            size="small"
            color='error'
          >
            Delete
          </StyledButton>
          
        </>
      );
    },
    
  },
 
  
];


export default RvaColumnShape;