import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import { Paragraph, Small, Tiny } from "components/Typography";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import AppModal from "components/AppModal";
import AppTextField from "components/input-fields/AppTextField";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import Scrollbar from "components/ScrollBar";
import { H3, H6, H4 } from "components/Typography";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axios";
import * as Yup from "yup"; // component props interface

import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";

import { useTranslation } from "react-i18next";
import JSONPretty from "react-json-pretty";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { ArrowOutward } from "@mui/icons-material";
import UserSymbol from "components/shared/UserSymbol";
import PaxfulAccount from "components/shared/PaxfulAccount";
// import PaxfulProfilePicForJSONData from "./ColumnShape";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Platform from "components/shared/Platform";
import PaxfulAccountForJSONData from "./profileWithAccount";
console.log("PaxfulAccount",PaxfulAccountForJSONData);

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 750,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

export const capitalize = (text) => {
  const str2 = text.charAt(0).toUpperCase() + text.slice(1);
  return str2;
};

const ViewPaxfulApiLogModal = ({ open, onClose, data }) => {
  console.log("data", data);
  const { t } = useTranslation();
  console.log("accounts",data);
  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <Box pb={4}>
        <H3 mb={3}>{capitalize(`${data?.account?.platform} Api Log Info`)}</H3>
        <Grid item md={12} xs={12} m={1}>
          <H6 color="text.secondary">{t("END POINT")}</H6>
          <Paragraph fontSize={13} color="text.secondary">
            {data.endpoint}
          </Paragraph>
        </Grid>
        <Grid container spacing={0} mt={0}>
          <Grid item md={4} xs={4}>
            <Grid container spacing={0} mt={0}>
              <Grid item md={12} xs={12} m={1}>
                <H6 color="text.secondary">{t("DATE")}</H6>
                <Paragraph fontSize={13} color="text.secondary">
                  <FormattedDateTime date={data.createdAt} />
                </Paragraph>
              </Grid>

              <Grid item md={12} xs={12} m={1}>
                <H6 color="text.secondary">{t("USER")}</H6>
                <H4 fontWeight={500}>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    {data.user && <UserSymbol user={data.user} size={25} />}

                    <Box
                      sx={{
                        marginLeft: "10px !important",
                      }}
                    >
                      <Paragraph fontSize={13} color="text.secondary">
                        {data.user == null ? "-" : data.user.first_name}
                      </Paragraph>
                    </Box>
                  </Stack>
                </H4>
              </Grid>
            </Grid>

            <Grid container spacing={0} mt={0}>
              <Grid item md={12} xs={12} m={1}>
                <H6 color="text.secondary">{t("ACCOUNT")}</H6>
                {/* <FlexBox justifyContent="start" alignItems="center">
                  <PaxfulProfilePic size={35} account={data.account} />
                  <Paragraph ml={1} fontSize={13} color="text.secondary">
                    {data.account.paxful_username}
                  </Paragraph>
                </FlexBox> */}
                {/* <PaxfulAccount item={data.account} size={30} />  */}
                <FlexBox justifyContent="start" alignItems="center">
               <PaxfulAccountForJSONData item={data.account} size={30} />
        </FlexBox>
              </Grid>
            </Grid>

            <Grid container spacing={0} mt={0}>
              <Grid item md={12} xs={12} m={1}>
                <H6 color="text.secondary">{t("STATUS")}</H6>
                <Small
                  sx={{
                    width: 100,
                    fontSize: 10,
                    color: "#fff",
                    borderRadius: "4px",
                    textAlign: "center",
                    padding: ".2rem 1rem",
                    backgroundColor:
                      data.status === "success" ? "success.main" : "error.main",
                  }}
                >
                  {data.status == "success" ? "success" : "Fail"}
                </Small>
              </Grid>
              {/* <Grid item md={12} xs={12} m={1}>
                <H6 color="text.secondary">{t("IP")}</H6>
                <Paragraph fontSize={13} color="text.secondary">
                  {data.ip}
                </Paragraph>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item md={8} xs={8}>
            <Grid m={1}>
              <div
                style={{
                  maxHeight: "200px",
                  overflow: "auto",
                  fontSize: "10px",
                }}
              >
                <H6 color="text.secondary">{t("RESPONSE")}</H6>
                <JSONPretty id="json-pretty" data={data.response}></JSONPretty>
              </div>
            </Grid>

            {/* <Grid m={1}>
              <div
                style={{
                  maxHeight: "200px",
                  overflow: "auto",
                  fontSize: "10px",
                }}
              >
                <H6 color="text.secondary">{t("QUERY")}</H6>
                <JSONPretty id="json-pretty" data={data.query}></JSONPretty>
              </div>
            </Grid>
            <Grid m={1}>
              <div
                style={{
                  maxHeight: "200px",
                  overflow: "auto",
                  fontSize: "10px",
                }}
              >
                <H6 color="text.secondary">{t("HEADER")}</H6>
                <JSONPretty id="json-pretty" data={data.header}></JSONPretty>
              </div>
            </Grid> */}
          </Grid>
        </Grid>

        {/* <Divider
            sx={{
              my: 3,
            }}
          /> */}
        {/* <Grid container spacing={0} mt={0}></Grid> */}
        {/* 
          <Divider
            sx={{
              my: 3,
            }}
          /> */}
      </Box>
    </StyledAppModal>
  );
};

export default ViewPaxfulApiLogModal;
