import axios from "../utils/axios";

const UserService = {};

UserService.getUsers = function (page, limit, type, search) {
  return axios({
    url: `/users?page=${page}&limit=${limit}&type=${type}&search=${search}`,
    method: "GET",
  });
};

UserService.getUserMasterList = function (page, limit, type, search) {
  return axios({
    url: `/users/all-user?page=${page}&limit=${limit}&type=${type}&search=${search}`,
    method: "GET",
  });
};
UserService.createUser = function (data) {
  return axios({
    url: "/users",
    method: "POST",
    data,
  });
};
UserService.updateUser = function (id, data) {
  return axios({
    url: `users/${id}`,
    method: "PUT",
    data,
  });
};
UserService.updateUserPlan = function (id, data) {
  return axios({
    url: `users/${id}/subscription`,
    method: "PUT",
    data,
  });
};
UserService.getUser = function (id, type, account) {
  return axios({
    url: `/users/${id}/?type=${type}&account=${account}`,
    method: "GET",
  });
};
UserService.getUserTeam = function (id, type) {
  return axios({
    url: `/users/${id}/team?type=${type}`,
    method: "GET",
  });
};
UserService.getUserTrade = function (id) {
  return axios({
    url: `/users/${id}/trade`,
    method: "GET",
  });
};
UserService.updatePassword = function (id, data) {
  return axios({
    url: `/users/${id}/password`,
    method: "PUT",
    data,
  });
};

UserService.DeleteUser = function (id, type) {
  return axios({
    url: `/users/${id}?type=${type}`,
    method: "DELETE",
  });
};

UserService.DisableMFA = function (id) {
  return axios({
    url: `/users/mfa-disable/${id}`,
    method: "POST",
  });
};

UserService.updateImage = function (id, data) {
  return axios({
    url: `/users/${id}/image`,
    method: "POST",
    data,
  });
};

UserService.testRoute = function (data) {
  return axios({
    url: `/test-routes/form-data`,
    method: "POST",
    data,
  });
};

UserService.updateSecurityPin = function (id, { pin_type, pin }) {
  return axios({
    url: `/users/${id}/pin-security`,
    method: "PUT",
    data: {
      pin_type,
      pin,
    },
  });
};
export default UserService;
