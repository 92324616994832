import React from "react";
import CryptoImg from "./CryptoImg";
import cryptoAssets from "data/supportedAssets";
import { styled } from "@mui/system";

const StyledSpan = styled("span")({
  position: "relative",
});

const OverlaySpan = styled("span")({
  position: "absolute",
  bottom: 0,
  left: 15,
});

function CryptoSymbol({ id, size = 20 }) {
  const currency = cryptoAssets.find(({ assetID }) => assetID === id);
  return (
    <StyledSpan style={{ width: size }}>
      {currency && (
        <>
          <CryptoImg size={size} currency={currency.code} className={"p-2"} />
          {currency?.assetID !== currency?.nativeAsset && (
            <OverlaySpan>
              <CryptoImg currency={currency?.nativeAsset} size={12} />
            </OverlaySpan>
          )}
        </>
      )}
    </StyledSpan>
  );
}

export default CryptoSymbol;

// import React from "react";
// import CryptoImg from "./CryptoImg";
// import cryptoAssets from "data/supportedAssets";
// import { Box } from "@mui/material";

// function CryptoSymbol({ id, size = 20 }) {
//   const currency = cryptoAssets.find(({ assetID }) => assetID === id);
//   return (
//     <Box sx={{ position: "relative", width: size }}>
//       {currency && (
//         <>
//           <CryptoImg size={size} currency={currency.code} />
//           {currency?.assetID !== currency?.nativeAsset && (
//             <Box sx={{ position: "absolute", bottom: 0, left: 15 }}>
//               <CryptoImg currency={currency?.nativeAsset} size={12} />
//             </Box>
//           )}
//         </>
//       )}
//     </Box>
//   );
// }

// export default CryptoSymbol;
