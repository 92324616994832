import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { H6, Paragraph, Small, Span, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, ContentCopy } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import styled from "@emotion/styled";
import { getUserReqStatus, getUserReqStatusColor } from "utils/utils";
import notification from "helpers/notifications";
import ATooltip from "components/shared/ATooltip";

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};
export const UserRequestColumnShape = (onCellClick, handleNavigate, tab) => [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>DATE </Box>,
    accessor: "createdAt",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        <FormattedDateTime date={value} />
      </Paragraph>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>VENDOR </Box>,
    accessor: "user",
    Cell: ({ value }) => (
      console.log("VALUE", value),
      (
        <Stack direction="row" spacing={2} alignItems={"center"}>
          {value && <UserSymbol user={value[0]} size={25} />}

          <Box
            sx={{
              marginLeft: "10px !important",
            }}
          >
            <Paragraph fontSize={13} color="text.secondary">
              <Link to={`/dashboard/users/${value[0]._id}`}>
                {value[0] == null ? "-" : value[0].first_name} {""}
                {value[0] == null ? "-" : value[0].last_name}
              </Link>
            </Paragraph>
          </Box>
        </Stack>
      )
    ),
  },
  // {
  //   Header: "SUBMITTED BY",
  //   accessor: "submittedBy",
  //   Cell: ({ value }) => (
  //     <Stack direction="row" spacing={2} alignItems={"center"}>
  //       {value && (
  //         <UserSymbol
  //           role={value[0].role}
  //           name={value[0].first_name ? value[0].first_name : value[0].email}
  //           size={25}
  //         />
  //       )}

  //       <Box
  //         sx={{
  //           marginLeft: "10px !important",
  //         }}
  //       >
  //         <Paragraph fontSize={13} color="text.secondary">
  //           {value[0] == null ? "-" : value[0].first_name}
  //         </Paragraph>
  //       </Box>
  //     </Stack>
  //   ),
  // },
  // {
  //   Header: "OFFERS",
  //   accessor: "details",
  //   Cell: ({ value }) => {
  //     let offers = value?.offers && JSON.parse(value?.offers);
  //     // console.log("offers", offers);
  //     return (
  //       <>
  //         {offers &&
  //           offers.map((item, index) => {
  //             return (
  //               <div key={index}>
  //                 <Paragraph fontSize={13} color="text.secondary">
  //                   {`${Object.keys(item)}[${item[Object.keys(item)]}]`}
  //                 </Paragraph>
  //               </div>
  //             );

  //             // <Paragraph fontSize={13} color="text.secondary">
  //             //   {item?.value}
  //             // </Paragraph>;
  //           })}
  //       </>
  //     );
  //   },
  // },
  {
    Header: () => <Box sx={{ marginLeft: "8px" }}>COMMENTS </Box>,
    accessor: "details.comment",
    Cell: ({ value }) => (
      <Box maxWidth={"380px"}>
        <Paragraph fontSize={13} color="text.secondary">
          {value}
        </Paragraph>
      </Box>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>STATUS </Box>,
    accessor: "status",
    Cell: ({ value }) => (
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".2rem 1rem",
          backgroundColor: getUserReqStatusColor(value),
        }}
      >
        {getUserReqStatus(value)}
      </Small>
    ),
  },
  {
    Header: "ACTIONS",
    accessor: "action",
    Cell: ({ row }) => {
      const { user, _id, status, type } = row.original;
      const isdisable = status == "closed" && type == "delete_account";
      const isdisables = status == "completed" || status == "closed";
      return (
        <>
          {tab != "delete_account" && (
            <>
              <StyledButton
                sx={{
                  ml: 2,
                }}
                onClick={() => handleNavigate(_id)}
                variant="outlined"
                color="primary"
                size="small"
              >
                VIEW
              </StyledButton>
              <StyledButton
                sx={{
                  ml: 2,
                }}
                onClick={() => onCellClick(_id, user[0]._id)}
                variant="outlined"
                color="error"
                size="small"
                disabled={isdisables}
              >
                DELETE
              </StyledButton>
            </>
          )}

          {tab == "delete_account" && (
            <StyledButton
              sx={{
                ml: 2,
              }}
              onClick={() => onCellClick(_id, user[0]._id)}
              variant="outlined"
              color="error"
              size="small"
              disabled={isdisable}
            >
              DELETE
            </StyledButton>
          )}
        </>
      );
    },
  },
];
export default UserRequestColumnShape;
