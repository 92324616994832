import { NineKOutlined } from "@mui/icons-material";
import { Box, Card, Divider, Grid, IconButton, styled } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { H5, H4, Small, Tiny } from "components/Typography";
import FlexBetween from "components/flexbox/FlexBetween";
import Facebook from "icons/Facebook";
import Instagram from "icons/Instagram";
import Twitter from "icons/Twitter";
import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
// styled components
const ImageWrapper = styled(Box)(({ theme }) => ({
  height: 100,
  position: "relative",
  "&::before": {
    content: '""',
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    position: "absolute",
    opacity: 0.6,
    backgroundColor: theme.palette.primary[100],
  },
}));
const StyledAvatar = styled(AppAvatar)(({ theme }) => ({
  zIndex: 1,
  width: 50,
  height: 50,
  bottom: -25,
  position: "absolute",
  left: "50%",
  right: "50%",
  transform: "translateX(-50%)",
  border: "2px solid",
  borderColor: theme.palette.background.paper,
}));

export function formatCurrency(
  amount,
  currencyCode = "USD",
  currencyDisplay = "symbol"
) {
  const formatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: currencyDisplay,
  });
  return formatter.format(amount);
}

const Wallet = () => {
  // Get data from usercontext api
  const { userdata } = useContext(UserContext);
  const wallet_balance = userdata?.wallet?.balance;
  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <FlexBetween>
        <H5>Atlantis Wallet</H5>
      </FlexBetween>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 2,
        }}
      >
        <h2>{formatCurrency(userdata?.wallet?.balance)}</h2>
        <Tiny color="text.secondary" fontWeight={500}>
          {"Balance"}
        </Tiny>
        <Tiny color="text.secondary" fontSize={10} fontWeight={500}>
          {userdata?.wallet?.deposit_address}
        </Tiny>
      </Box>

      <Divider
        sx={{
          my: 2,
        }}
      />

      <Grid container>
        <Grid item xs={6} textAlign="start">
          <Small color="text.secondary" fontSize={12}>
            Trading Volume
          </Small>
        </Grid>

        <Grid item xs={6} textAlign="end">
          <Small color="text.secondary" fontWeight={600}>
            {formatCurrency(userdata?.wallet?.balance * 1.25)}
          </Small>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Wallet;
