import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import ATooltip from "./ATooltip";
import PaxfulAccount from "./PaxfulAccount";
import UserSymbol from "./UserSymbol";
import CreatedBy from "./CreatedBy";
import UserInfo from "./UserInfo";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlexBox from "components/flexbox/FlexBox";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LanguageIcon from "@mui/icons-material/Language";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function UserGroup({ users }) {
  let max = 5;
  const [showAll, setShowAll] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleShowClick = (value) => {
    setShowAll(!value);
  };

  const openModalWithAllUser = () => {
    handleShowClick(false);
    handleOpen();
  };

  // Slice the users based on whether to show all or not
  const displayedUsers = showAll ? users : users.slice(0, max);

  return (
    <>
      <FlexBox
        sx={{
          alignItems: "center",
        }}
      >
        {users &&
          users.slice(0, max).map((user) => {
            return (
              <>
                <ATooltip
                  content={
                    <UserInfo
                      user={user.user}
                      showOnlineStatus={false}
                      devices={user.devices}
                    />
                  }
                >
                  <UserSymbol user={user.user} size={25} />
                </ATooltip>
              </>
            );
          })}
        {users && users.length > 1 && (
          <ATooltip content={`Show all users`} placement="top">
            <IconButton
              onClick={() => {
                openModalWithAllUser();
              }}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </ATooltip>
        )}

        {/* {users.length == displayedUsers.length && showAll && (
          <ATooltip content={`Show less users`} placement="top">
            <IconButton onClick={() => handleShowClick(true)}>
              <ArrowLeftIcon />
            </IconButton>
          </ATooltip>
        )} */}
      </FlexBox>

      <Box>
        <Modal
          sx={{
            border: "none",
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              mb={2}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              All Users
            </Typography>

            {users.map((user) => {
              return (
                <FlexBox
                  pb={1}
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <UserInfo user={user.user} showOnlineStatus={false} />
                  <FlexBox mt={1} sx={{ justifyContent: "flex-end" }}>
                    {user.devices && user.devices.includes("web") && (
                      <LanguageIcon />
                    )}
                    {user.devices &&
                      user.devices.includes("android") &&
                      user.devices.includes("ios") && <PhoneAndroidIcon />}
                  </FlexBox>
                </FlexBox>
              );
            })}
          </Box>
        </Modal>
      </Box>
    </>
  );
}

export default UserGroup;
